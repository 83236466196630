<!-- =========================================================================================
    File Name: PaginationLinksLimit.vue
    Description: Limit the amount of links shown
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
            <vs-pagination :total="10" v-model="currentx" :max="5"></vs-pagination>
</template>

<script>
export default {
    data: () => ({
        currentx: 3
    })
}
</script>
