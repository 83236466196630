<template>
  <div>
    <vs-tabs class="contact-tab camp-tab mt-8" v-model="mainActivetab">
      <!-- Calendar -->
      <vs-tab label="Calendar" icon-pack="feather" icon="icon-calendar">
        <div class="calendar">
          <full-calendar
            class="full-calendar campaign-calendar"
            :options="configOptions"
          >
            <template #eventContent="{ timeText, event }">
              <b>{{ timeText }}</b>
              <i>{{ event.title }}</i>
            </template>
          </full-calendar>
        </div>
        <!--<div id="simple-calendar-app">-->
        <!-- Calendar -->
        <!--<calendar-view
            ref="calendar"
            :events="eventData"
            :displayPeriodUom="calendarView"
            :show-date="showDate"
            @click-event="openEditEvent"
            @drop-on-date="eventDragged"
            enableDragDrop
            :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
            eventContentHeight="1.5rem"
            eventBorderHeight="0px"
            class="theme-default mt-5"
          >
            <div slot="header" class="mb-3 header-month">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex items-center justify-between">
                    <feather-icon
                      :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                      @click="updateMonth(-1)"
                      svgClasses="w-5 h-5 m-1"
                      class="cursor-pointer bg-primary text-white rounded-full"
                    />
                    <span
                      class="mx-3 text-xl font-medium whitespace-no-wrap month-text"
                      >{{ showDate | month }}</span
                    >
                    <feather-icon
                      :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                      @click="updateMonth(1)"
                      svgClasses="w-5 h-5 m-1"
                      class="cursor-pointer bg-primary text-white rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </calendar-view>
        </div>-->
      </vs-tab>

      <!-- Campaigns -->
      <vs-tab label="Campaigns" icon-pack="feather" icon="icon-settings">
        <!-- Create Campaign -->
        <div class="card-title justify-between mt-4">
          <h2 class="w-auto">Campaigns</h2>
          <vs-button
            title="Update"
            type="filled"
            class="small-btn"
            color="primary"
            @click="createCampaignActive = true"
            >{{ LabelConstant.buttonLabelCreateCampaign }}</vs-button
          >
        </div>
        <!-- Filter By type, Order By, Search Text, Search Button -->
        <div class="search-select-option">
          <div class="vx-row">
            <!-- Filter By type -->
            <div class="vx-col w-full lg:w-1/4 mb-4">
              <v-select
                v-model="filterByType"
                label="campaignType"
                placeholder="Campaign Type"
                :options="TypeOptions"
                :clearable="false"
              />
            </div>
            <!-- Order By -->
            <div class="vx-col w-full lg:w-1/4 mb-4">
              <v-select
                v-model="order"
                label="orderBy"
                placeholder="Order By"
                :options="orderByOptions"
                :reduce="(options) => options.key"
                @input="sortCampaign()"
              />
            </div>
            <!-- Search Text -->
            <div class="vx-col w-full lg:w-1/4 mb-4">
              <vs-input
                icon-pack="feather"
                icon="icon-search"
                placeholder="Campaign Name"
                v-model="searchTerm"
                class="is-label-placeholder mt-0"
              />
            </div>
            <!-- Search Button -->
            <div class="vx-col w-full lg:w-1/4 mb-4">
              <vs-button
                @click="searchCampaignByName()"
                type="filled"
                class="small-btn bg-actionbutton"
                >{{ LabelConstant.buttonLabelSearch }}</vs-button
              >
            </div>
          </div>
        </div>
        <div class="monthly-newsletter">
          <!-- Pagination -->
          <div class="vx-row">
            <div class="vx-col w-full text-right mt-5">
              <jw-pagination
                class="jw-pagination"
                :items="campaigns"
                @changePage="onChangePage"
              ></jw-pagination>
              <!-- <pagination :total="15"  :max="5"></pagination> -->
            </div>
          </div>
          <!-- Campaigns Listing -->
          <div class="vx-row">
            <div
              class="vx-col w-full"
              v-for="(campaign, index) in pageOfItems"
              :key="index"
              v-if="campaign.Campaign_Type == filterByType.campaignType || filterByType.campaignType == 'All'"
            >
              <div class="newsletter-block">
                <div class="vx-row justify-between">
                  <div class="vx-col w-full md:w-1/3">
                    <h2>{{ campaign.Campaign_Name }}</h2>
                    <span class="edit-time"
                      >Edited {{ formateDate(campaign.Date_Updated) }} By
                      {{ campaign.Updated_By }}</span
                    >
                    <span class="rag-camp mb-2"
                      >{{ campaign.Campaign_Type }} Campaign</span
                    >
                    <span
                      class="edit-time mb-0"
                      v-if="campaign.Campaign_Status == 'Sent'"
                      >Sent On {{ campaign.Date_Sent_Long }}</span
                    >
                    <span
                      class="edit-time mb-0"
                      v-if="campaign.Campaign_Status == 'Scheduled'"
                      >Scheduled On {{ campaign.Date_Sent_Long }}</span
                    >
                    <p v-if="campaign.Segment != 'none'">
                      Segment: {{ campaign.Segment }}
                    </p>
                  </div>
                  <div
                    class="vx-col w-full md:w-2/5"
                    v-if="campaign.Campaign_Status == 'Sent'"
                  >
                    <div class="newsletter-chart">
                      <div class="chart-block">
                        <!-- <radial-small-chart
                          :chartTitle="'Open Rate'"
                          :otherData="campaign.Open_Count"
                          :chartType="'radialBar'"
                          :chartHeight="100"
                          :chartSeries="campaign.Open_Rate_Chart.series"
                          :chartOptions="campaign.Open_Rate_Chart.chartOptions"
                        ></radial-small-chart> -->
                        <h4>
                          Open Rate <span>{{ campaign.Open_Count }}</span>
                        </h4>
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="campaign.Open_Rate_Chart.chartOptions"
                          :series="campaign.Open_Rate_Chart.series"
                        ></vue-apex-charts>
                      </div>
                      <div class="chart-block">
                        <h4>
                          Click Rate <span>{{ campaign.Click_Count }}</span>
                        </h4>
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="campaign.Click_Rate_Chart.chartOptions"
                          :series="campaign.Click_Rate_Chart.series"
                        ></vue-apex-charts>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col w-full md:w-3/12">
                    <div class="time-week">
                      <vx-tooltip
                        text="Draft"
                        v-if="campaign.Campaign_Status == 'Draft'"
                      >
                        <feather-icon icon="Edit3Icon"></feather-icon>
                      </vx-tooltip>
                      <vx-tooltip
                        text="Sent"
                        v-if="campaign.Campaign_Status == 'Sent'"
                      >
                        <feather-icon
                          icon="SendIcon"
                          class="text-success"
                        ></feather-icon>
                      </vx-tooltip>
                      <vx-tooltip
                        text="Scheduled"
                        v-if="campaign.Campaign_Status == 'Scheduled'"
                      >
                        <feather-icon
                          icon="ClockIcon"
                          class="text-warning"
                        ></feather-icon>
                      </vx-tooltip>
                    </div>
                    <h4
                      class="text-success inline-block sent-text"
                      v-if="campaign.Campaign_Status == 'Sent'"
                    >
                      Sent
                    </h4>
                    <h4
                      class="text-warning inline-block scheduled-text"
                      v-if="campaign.Campaign_Status == 'Scheduled'"
                    >
                      Scheduled
                    </h4>
                    <h4
                      class="text-secondary inline-block draft-text"
                      v-if="campaign.Campaign_Status == 'Draft'"
                    >
                      Draft
                    </h4>
                    <v-select
                      @input="changeAction(campaign.Id,campaign.Action,campaign.Campaign_Name,campaign.Date_Scheduled)"
                      v-model="campaign.Action"
                      label="draftName"
                      :options="campaign.Campaign_Status == 'Draft' || campaign.Campaign_Status == 'Scheduled' ? draftOptions : sentOptions"
                      placeholder="Select Action"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination -->
          <div class="vx-row">
            <div class="vx-col w-full text-right mt-5">
              <jw-pagination
                class="jw-pagination"
                :items="campaigns"
                @changePage="onChangePage"
              ></jw-pagination>
              <!-- <pagination :total="15"  :max="5"></pagination> -->
            </div>
          </div>
        </div>
      </vs-tab>

      <!-- Email Templates -->
      <vs-tab label="Email Templates" icon-pack="feather" icon="icon-mail">
        <div class="email-temp">
          <!-- Add Folder, Create Email Template Button -->
          <div class="vx-row justify-end mb-6">
            <div class="vx-col">
              <!-- Add Folder -->
              <vs-button
                @click="addEditFolderPopupTitle = 'Add Folder',addEditFolderPopupActive = true"
                type="filled"
                color="secondary"
                class="mb-4 md:mb-2"
                >{{ LabelConstant.buttonLabelAddFolder }}</vs-button
              >
              <!-- Create Email Template Button -->
              <vs-button
                @click="openCreateEditEmailTemplateEditor(),createEmailTemplateTitle = 'Create Email Template',editEmailTemplateMode = false"
                color="primary"
                type="filled"
                class="mb-4 md:mb-2 ml-2"
                >{{ LabelConstant.buttonLabelCreateEmailTemplate }}</vs-button
              >
            </div>
          </div>
          <!-- Sorting - Searching options -->
          <div class="vx-row">
            <!-- Folder dropdown -->
            <div class="vx-col lg:w-4/12 mb-4 lg:mb-0 w-full">
              <v-select
                class="folder-select"
                label="Folder_Name"
                v-model="selectedFolderForSearching"
                :reduce="(option) => option.Id"
                :options="emailTemplateFoldersList"
                :closeOnSelect="false"
                :searchable="false"
                @input="cancelAddUpdateEmailTemplateFolder()"
                ref="theSelect"
              >
                <template v-slot:option="option">
                  <div>
                    <div class="folder-button">
                      <vs-button
                        @click="deleteFolderPopupActive = true,editableFolderId = option.Id,editableFolderName = option.Folder_Name"
                        type="filled"
                        class=""
                        color="danger"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="text-danger"
                        ></feather-icon>
                      </vs-button>
                      <vs-button
                        @click="editableFolderId = option.Id,editableFolderItemCount = option.Current_Item_Count,editableFolderName = option.Folder_Name,addEditFolderPopupActive = true,addEditFolderPopupTitle = 'Edit Folder',editFolderMode = true"
                        type="filled"
                        class=""
                        color="success"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="text-primary"
                        ></feather-icon>
                      </vs-button>
                    </div>
                    <div class="folder-name">
                      <span>{{ option.Folder_Name }}</span>
                      <span>{{ option.Current_Item_Count }}</span>
                    </div>
                  </div>
                </template>
              </v-select>
            </div>
            <!-- Template sorting dropdown -->
            <div class="vx-col xl:w-1/12 mb-4 lg:mb-0 lg:w-1/6 w-full">
              <span class="float-left w-full text-center lg:mt-3 mt-0"
                ><b>Sort By</b></span
              >
            </div>
            <div class="vx-col lg:w-2/12 mb-4 lg:mb-0 w-full">
              <v-select
                v-model="emailTemplateSortingOrder"
                label="orderName"
                placeholder="Sort By"
                :options="emailTemplateSortByOptions"
                :clearable="false"
                @input="sortEmailTemplate()"
              />
            </div>
            <!-- Search by email template name -->
            <div class="vx-col lg:w-5/12 lg:mb-0 w-full">
              <div class="search-inputbutton">
                <vs-input
                  icon-pack="feather"
                  icon="icon-search"
                  placeholder="Search"
                  v-model="emailTemplateSearchTerm"
                  class="is-label-placeholder w-full mt-0"
                />
                <vs-button
                  @click="searchEmailTemplateByName()"
                  type="filled"
                  class="small-btn bg-actionbutton"
                  >{{ LabelConstant.buttonLabelSearch }}</vs-button
                >
              </div>
            </div>
          </div>
          <!-- Template listing -->
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/2"
              v-for="(savedEmailTemplate, index) in savedEmailTemplatesList"
              :key="index"
              v-if="savedEmailTemplate.Folder_Id == selectedFolderForSearching || !selectedFolderForSearching"
            >
              <div class="template-info">
                <div class="template-img">
                  <img src="../../../src/assets/images/post-img.jpg" />
                </div>
                <div class="template-content">
                  <h2>{{ savedEmailTemplate.Template_Name }}</h2>
                  <span class="edit-date">
                    <b>Last Edited</b> on
                    {{ savedEmailTemplate.Date_Updated | templatEditedDateTime }} by {{ savedEmailTemplate.Updated_By }}
                  </span>
                  <div class="temp-buttons">
                    <vs-button
                      title="Edit"
                      @click="getSavedEmailTemplate(savedEmailTemplate.Id),createEmailTemplateTitle = 'Edit Email Template',editEmailTemplateMode = true"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-edit"
                      class="mr-2 float-left"
                      color="primary"
                    ></vs-button>
                    <vs-button
                      title="Create Campaign"
                      @click="addSegmentActive = true"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      class="mr-2 float-left"
                      color="secondary"
                    ></vs-button>
                    <vs-button
                      :title="savedEmailTemplate.Template_Name + ' - Copy'"
                      @click="getSavedEmailTemplateForCopy()"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-refresh-cw"
                      class="mr-2 float-left"
                      color="success"
                    ></vs-button>
                    <vs-button
                      title="Delete"
                      @click="deleteEmailTemplatePopupActive = true,deletableEmailTemplateId = savedEmailTemplate.Id,deletableEmailTemplate = savedEmailTemplate.Template_Name"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-trash"
                      class="mr-2 float-left"
                      color="danger"
                    ></vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

   <!--Contact popup-->
    <vs-popup    title="Contact"  class="popup-width"
      :active.sync="contactDetailsActive">  
      <div>
           <div class="flex flex-wrap justify-between items-center w-full">
              <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                  >
                    <span class="mr-2"
                      >{{
                        currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                      }}
                      -
                      {{
                        contactsData.length - currentPage * paginationPageSize >
                        0
                          ? currentPage * paginationPageSize
                          : contactsData.length
                      }}
                      of {{ contactsData.length }}</span
                    >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(5)">
                      <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(10)"
                    >
                      <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(20)"
                    >
                      <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(25)"
                    >
                      <span>25</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(50)"
                    >
                      <span>50</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(100)"
                    >
                      <span>100</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(200)"
                    >
                      <span>200</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(300)"
                    >
                      <span>300</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(400)"
                    >
                      <span>400</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="gridApi.paginationSetPageSize(500)"
                    >
                      <span>500</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <div
                class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              >
                <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchQuery"
                  @input="updateSearchQuery"
                  placeholder="Search..."
                />
              </div>
            </div>
            <!-- Contacts table -->
            <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table popup-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="contactsData"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="5"
              :suppressPaginationPanel="true"
              :frameworkComponents="frameworkComponents"
              :context="context"
              :gridReady="onGridReady()"
              :enableRtl="$vs.rtl"
            >
            </ag-grid-vue>
            <vs-pagination
              class="ag-grid-pagination-area"
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage"
            />
      </div>
       <!-- <vs-button
        @click="contactsData=[],columnDefs=[],contactDetailsActive=false"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      > -->
       <div class="float-right mt-6 mb-8">
        <vs-button
          color="grey"
          @click="contactsData=[],columnDefs=[],contactDetailsActive=false"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    
    <!-- View Email Template Design -->
    <vs-popup
      class="tag-info"
      title="View Email Template"
      :active.sync="viewEmailTemplate"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div v-if="emailThumbnail">
              <img
                class="mail-sub-img"
                :src="'data:image/png;base64, ' + emailThumbnail"
              />
            </div>
            <div class="vx-row">
              <span class="sub-text big-text">{{ emailText }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          color="grey"
          @click="cancelViewEmailDetails()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- View Calendar Campaign -->
    <vs-prompt
      class="calendar-event-dialog tag-info event-dialog close-open"
      title="Email"
      :active.sync="activePromptAddEvent"
    >
      <vs-button
        @click="(viewDetails = null), (activePromptAddEvent = false)"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <div class="news-event">
        <h2 class="mb-4">
          {{ viewDetails ? viewDetails.Campaign_Name : "" }}
          <vs-chip
            :color="
              viewDetails
                ? viewDetails.Campaign_Status == 'Scheduled'
                  ? 'warning'
                  : 'success'
                : ''
            "
            class="m-0 sm:ml-2 my-2"
            >{{ viewDetails ? viewDetails.Campaign_Status : "" }}</vs-chip
          >
        </h2>
        <table class="view-template">
          <tr>
            <th>Type</th>
            <td>Email</td>
          </tr>
          <tr>
            <th>Audience</th>
            <td>{{ viewDetails ? viewDetails.Segment : "" }}</td>
          </tr>
          <tr>
            <th>{{ viewDetails ? viewDetails.Campaign_Status : "" }}</th>
            <td>{{ viewDetails ? viewDateFormat(viewDetails.Date_Sent_Long) : "" }}</td>
          </tr>
        </table>
        <ul>
          <li>
            <b>{{ viewDetails ? viewDetails.Total_Sent : 0 }}K</b> Sends
          </li>
          <li>
            <b>{{ viewDetails ? viewDetails.Open_Rate : 0 }}%</b> Opens
          </li>
          <li>
            <b>{{ viewDetails ? viewDetails.Click_Rate : 0 }}%</b> Clicks
          </li>
        </ul>
        <vs-button
          type="filled"
          class="w-full bg-actionbutton small-btn mb-6"
          @click="getCampaignOverviewReport(viewDetails ? viewDetails.Id : 0)"
          >{{ LabelConstant.buttonLabelViewReport }}</vs-button
        >
      </div>
      <div class="flex w-full justify-end mb-8">
        <vs-button
          type="filled"
          color="primary"
          class="small-btn"
          @click="viewEmailDetails(viewDetails ? viewDetails.Id : 0)"
          >{{ LabelConstant.buttonLabelViewEmail }}</vs-button
        >
      </div>
    </vs-prompt>

    <!-- Schedule -->
    <vs-popup
      class="tag-info"
      title="Schedule your campaign"
      :active.sync="addScheduleActive"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/schedule.png" />
            </div>
            <form data-vv-scope="scheduleform">
              <div class="vx-row">
                <div class="vx-col w-full mt-6 md:w-1/2">
                  <label>Select Date</label>
                  <flat-pickr
                    class="mt-0"
                    v-model="Schedule_Date"
                    :config="configdateTimePicker"
                    placeholder="Choose Date"
                    name="Schedule_Date"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm mb-3 float-left w-full">{{
                    errors.first("scheduleform.Schedule_Date")
                  }}</span>
                </div>
                <div class="vx-col w-full mt-6 md:w-1/2">
                  <label>Set Time</label>
                  <flat-pickr
                    class="mt-0"
                    :config="configTimePicker"
                    v-model="Schedule_Time"
                    name="Schedule_Time"
                    placeholder="Choose time"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm mb-3 float-left w-full">{{
                    errors.first("scheduleform.Schedule_Time")
                  }}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="scheduleValidate()"
          >{{ LabelConstant.buttonLabelOk }}</vs-button
        >
        <vs-button
          color="grey"
          @click="scheduleCancel()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Select Email Template Design -->
    <vs-popup
      class="popup-width"
      title="Email Designer"
      :active.sync="emailDesignActive"
    >
      <template>
        <div class="watch-list layout-list">
          <vs-tabs v-model="activeTab">
            <vs-tab label="Layout" icon-pack="feather" icon="icon-layout">
              <div class="layout-main">
                <div class="vx-row">
                  <div class="vx-col w-full lg:px-2"><h4>Featured</h4></div>
                </div>
                <div class="vx-row">
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>Sell Products</h4>
                      <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>Make an announcement</h4>
                      <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>Tell a Story</h4>
                      <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>Follow Up</h4>
                      <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="layout-main">
                <div class="vx-row">
                  <div class="vx-col w-full lg:px-2"><h4>Basic</h4></div>
                </div>
                <div class="vx-row">
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1 column</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1 column - full width</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.2 column</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.2 column - full width</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.2.1 column</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.2.1 column - full width</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.3 column</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>1.3 column - full width</h4>
                    </div>
                  </div>
                  <div
                    class="vx-col w-full lg:w-1/4 md:w-1/3 sm:w-1/2 mb-6 lg:p-2"
                  >
                    <div class="layout-block">
                      <span><img src="../../assets/images/layout.png" /></span>
                      <h4>Simple Text</h4>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              label="Prebuilt Templates"
              icon-pack="feather"
              icon="icon-layout"
            >
              <div class="layout-main">
                <div class="vx-row">
                  <div class="vx-col w-full lg:px-2 mb-2">
                    <vs-input
                      icon-pack="feather"
                      icon="icon-search"
                      placeholder="Search"
                      v-model="prebuiltSearch"
                      class="is-label-placeholder float-right md:w-1/2 w-full mt-0"
                    />
                  </div>
                  <div class="vx-col w-full lg:px-2"><h4>Ecommerce</h4></div>
                </div>
                <div class="vx-row">
                  <div
                    class="vx-col w-full lg:w-1/3 md:w-1/2 lg:p-2"
                    v-for="(temp, index) in preBuiltTemplates"
                    :key="index"
                  >
                    <div
                      class="layout-block lg:mb-0 mb-3"
                      :class="emailTemplateType == 'prebuilt' && emailTemplateSelected == index ? 'active' : ''"
                      @click="selectTemplate('prebuilt', index, temp.Json_Content)"
                    >
                      <span>
                        <img :src="'data:image/png;base64, ' + temp.Template_Image" />
                      </span>
                      <h4>{{ temp.Template_Name }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              label="Your Saved Templates"
              icon-pack="feather"
              icon="icon-layout"
            >
              <div class="layout-main">
                <div class="layout-filter">
                  <div class="vx-row">
                    <div class="vx-col lg:w-1/4 w-full px-2">
                      <div class="folder">
                        <v-select
                          @input="filterTemplate()"
                          v-model="filterEmailTemplateFolder"
                          label="Folder_Name"
                          placeholder="Select Folder"
                          :options="emailTemplateFoldersList"
                          :reduce="(option) => option.Id"
                        />
                        <!-- <div class="select-box"></div> -->
                        <!-- <ul class="select-options">
                          <li>
                            <span>All</span>
                          </li>
                          <li>
                            <span>Unfiled</span>
                            <span class="option-no">3</span>
                          </li>
                          <li>
                            <span>New Folder 0</span>
                            <span class="option-no">0</span>
                          </li>
                          <li>
                            <span>New Folder</span>
                            <span class="option-no">0</span>
                          </li>
                          <li>
                            <span>Interface Updates</span>
                            <span class="option-no">1</span>
                          </li>
                          <li>
                            <a href="#">Add Folder</a>
                            <a href="#">Edit Folder</a>
                          </li>
                        </ul> -->
                      </div>
                    </div>
                    <div class="vx-col lg:w-1/6 px-2 w-full">
                      <span class="float-left w-full text-center mt-3"
                        ><b>Sort By</b></span
                      >
                    </div>
                    <div class="vx-col lg:w-1/4 w-full px-2">
                      <v-select
                        class="w-full"
                        v-model="sortBySelected"
                        label="sortBy"
                        :options="sortByOptions"
                      />
                    </div>
                    <div class="vx-col lg:w-1/3 w-full px-2">
                      <vs-button
                        @click="sortASC = !sortASC"
                        class="float-left mr-2"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        :icon="sortASC ? 'icon-arrow-up' : 'icon-arrow-down'"
                      ></vs-button>
                      <vs-input
                        @input="filterTemplate()"
                        icon-pack="feather"
                        icon="icon-search"
                        placeholder="Search"
                        v-model="searchSavedTemp"
                        class="is-label-placeholder mt-0 w-full"
                      />
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div
                    class="vx-col w-full lg:w-1/3 lg:p-2"
                    v-for="(savedtemp, index) in savedEmailTemplatesList"
                    :key="index"
                  >
                    <div
                      class="layout-block"
                      :class="
                        emailTemplateType == 'saved' &&
                        emailTemplateSelected == index
                          ? 'active'
                          : ''
                      "
                      @click="
                        selectTemplate('saved', index, savedtemp.Json_Content)
                      "
                    >
                      <span
                        ><img
                          :src="
                            'data:image/png;base64, ' + savedtemp.Template_Image
                          "
                      /></span>
                      <h4>{{ savedtemp.Template_Name }}</h4>
                      <span class="edited-date">
                        Last Edited <br />
                        {{ savedtemp.Date_Updated | templatEditedDateTime }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="ShowEmailBuilder()"
          >{{ LabelConstant.buttonLabelAdd }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelTemplateSelection()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
      
    <vs-popup title="Warning" :active.sync="warningMessagePopup">
      <div class="text-center">
        <p class="mb-4"> {{ warningMessage }}</p>
        <vs-button
          color="success"
          @click="cancelWarningPopup()"
          type="border"
          class="mt-3  mb-5 success-btn"
        >{{ LabelConstant.buttonLabelOk }}</vs-button>
      </div>
    </vs-popup>

    <!-- Create Campaign -->
    <vs-popup
      class="popup-width"
      title="Create New Email Campaign"
      :active.sync="createCampaignActive"
    >
      <template>
        <!-- Step1: Select campaign type and enter name -->
        <div class="campaign-first mb-6" v-if="!nextTrue">
          <form data-vv-scope="campaignvalidate">
            <div class="vx-row justify-center">
              <div class="vx-col w-full md:w-2/3">
                <!-- Select campaign name -->
                <div class="vx-row">
                  <div class="vx-col w-full md:w-1/3">
                    <div class="campaign-img">
                      <input
                        type="radio"
                        name="email-camp"
                        value="Regular"
                        v-model="campaignType"
                      />
                      <img src="../../assets/images/avatar-s-11.png" />
                      <h3>Regular - Email Campaign</h3>
                    </div>
                  </div>
                  <div class="vx-col w-full md:w-1/3">
                    <div class="campaign-img">
                      <input
                        type="radio"
                        name="email-camp"
                        value="Text"
                        v-model="campaignType"
                      />
                      <img src="../../assets/images/avatar-s-11.png" />
                      <h3>Plain Text - Email Campaign</h3>
                    </div>
                  </div>
                  <div class="vx-col w-full md:w-1/3">
                    <div class="campaign-img">
                      <input
                        type="radio"
                        name="email-camp"
                        value="AB"
                        v-model="campaignType"
                      />
                      <img src="../../assets/images/avatar-s-11.png" />
                      <h3>A/B Split - Email Campaign</h3>
                    </div>
                  </div>
                </div>
                <!-- Enter campaign name -->
                <div class="vx-row">
                  <div class="vx-col w-full mt-8">
                    <vs-input
                      type="text"
                      class="w-full"
                      name="campaignName"
                      label-placeholder="Email Campaign Name"
                      v-validate="'required'"
                      v-model="campaignName"
                    />
                    <span class="text-danger text-sm mb-3 float-left w-full">{{
                      errors.first("campaignvalidate.campaignName")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Next and Cancel button -->
            <div class="vx-row">
              <div class="float-right vx-col w-full justify-end flex mt-8 mb-8">
                <vs-button color="primary" type="filled" @click="nextStep()"
                  >{{ LabelConstant.buttonLabelNext }}</vs-button
                >
                <vs-button
                  color="grey"
                  @click="cancelCampaign()"
                  type="border"
                  class="ml-4 grey-btn"
                  >{{ LabelConstant.buttonLabelCancel }}</vs-button
                >
              </div>
            </div>
          </form>
        </div>
        <!-- Step1: -->
        <div class="campaign-second" v-if="nextTrue">
          <div class="vx-row">
            <!-- Edit campaign name, campaign type, Select Schedule Time, Schedule, Send, Save changes and work on it later -->
            <div class="vx-col w-full">
              <div class="card-title">
                <!-- Edit campaign name, campaign type -->
                <h2>
                  <a href="#"
                    ><feather-icon
                      icon="EditIcon"
                      class="text-primary"
                      v-if="campaignReadOnly"
                      @click="campaignReadOnly = false"
                    ></feather-icon
                  ></a>
                  <input
                    name="campaignName"
                    type="text"
                    v-model="campaignName"
                    :readonly="campaignReadOnly"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm mb-3 float-left w-full">{{
                    errors.first("campaignName")
                  }}</span>
                  <h4>
                    <span>{{ campaignType }} Campaign</span>
                  </h4>
                </h2>
                <!-- Select Schedule Time, Schedule, Send, Save changes and work on it later -->
                <div class="flex flex-wrap justify-end flex-col md:items-end">
                  <div class="block">
                    <vs-button
                      color="primary"
                      type="filled"
                      class="small-btn mr-2 sm:w-auto w-full sm:mt-0 mt-2"
                      @click="addScheduleActive = true"
                      >{{ LabelConstant.buttonLabelSeletScheduleTime }}</vs-button
                    >
                    <!-- <vs-button
                      color="primary"
                      type="filled"
                      class="small-btn sm:w-auto w-full sm:mt-0 mt-2"
                       @click="addScheduleActive = true"
                      >Schedule</vs-button
                    > -->
                    <vs-button
                      class="bg-actionbutton small-btn sm:ml-2 sm:mt-0 mt-2 ml-0 sm:w-auto w-full"
                      type="filled"
                      @click="saveCampaigns('sent')"
                      >{{ LabelConstant.buttonLabelSend }}</vs-button
                    >
                  </div>
                  <a
                    href="#"
                    class="save-change"
                    @click="saveCampaigns('draft')"
                    >{{ LabelConstant.linkLabelSavechangesAndWorkOnItLater }}</a
                  >
                </div>
              </div>
            </div>
            <!-- IF A/B Campaign -->
            <!-- What would you like to test? -->
            <div class="vx-col w-full" v-if="campaignType == 'AB'">
              <div class="newsletter-block">
                <h3>What would you like to test?</h3>
                <!-- Test Type -->
                <div class="test-type">
                  <h4>Test Type</h4>
                  <ul class="type-select">
                    <li>
                      <vs-radio
                        v-model="testtype"
                        vs-value="subjectline"
                        @change="changeNumberOrType('testtype')"
                        >Subject Line</vs-radio
                      >
                    </li>
                    <li>
                      <vs-radio
                        v-model="testtype"
                        @change="changeNumberOrType('testtype')"
                        vs-value="from"
                        >From</vs-radio
                      >
                    </li>
                    <li>
                      <vs-radio
                        v-model="testtype"
                        vs-value="content"
                        @change="changeNumberOrType('testtype')"
                        >Content</vs-radio
                      >
                    </li>
                  </ul>
                </div>
                <!-- Number of variations -->
                <div class="test-type" v-if="testtype">
                  <h4>
                    Number of
                    {{
                      testtype == "subjectline"
                        ? "(subject line)"
                        : testtype == "from"
                        ? "(from)"
                        : testtype == "content"
                        ? "(content)"
                        : ""
                    }}
                    Variations
                  </h4>
                  <v-select
                    @input="changeNumberOrType('number')"
                    :clearable="false"
                    class="md:w-1/3 sm:w-1/2 w-full"
                    v-model="numberVariations"
                    label="variationsName"
                    :options="variationsOptions"
                    :reduce="
                      (variationsOptions) => variationsOptions.variationsName
                    "
                  />
                </div>
                <!-- percent selector slider -->
                <div class="test-type">
                  <h4 class="mb-1">
                    What percentage of recipents should receive this test
                    combination before selecting a winner ?
                  </h4>
                  <p class="mb-2 float-none">
                    After the winner is selected, the remaining emails will be
                    sent using the winning combination.
                  </p>
                  <div class="mt-10 mb-4 px-5">
                    <vue-range-slider
                      ref="slider"
                      :min="1"
                      v-model="Percent_To_Test_To_Pick_Winner"
                      :formatter="formatter"
                      :tooltip-merge="tooltipMerge"
                      :tooltip="'always'"
                    ></vue-range-slider>
                  </div>
                </div>
                <!-- How would you like to determine the winner? -->
                <div class="test-type">
                  <h4>How would you like to determine the winner?</h4>
                  <div class="float-left w-full">
                    <div class="vx-row">
                      <div class="vx-col md:w-1/4 md:mb-0 mb-3 w-full">
                        <v-select
                          class="w-full"
                          v-model="Winner_Determining_Factor"
                          label="rateLabel"
                          :options="rateOptions"
                          :reduce="(rateOptions) => rateOptions.rateLabel"
                        />
                      </div>
                      <div class="vx-col md:w-1/6 md:mb-0 mb-3 w-full">
                        <span class="float-left w-full text-center md:mt-3 mt-0"
                          ><b>After</b></span
                        >
                      </div>
                      <div class="vx-col md:w-1/4 md:mb-0 mb-3 w-full">
                        <vs-input
                          type="number"
                          class="w-full"
                          name="Winner_Determining_After_Value"
                          v-model="Winner_Determining_After_Value"
                        />
                      </div>
                      <div class="vx-col md:w-1/3 md:mb-0 w-full">
                        <v-select
                          class="w-full"
                          v-model="Winner_Determining_After_Type"
                          label="selectDay"
                          :options="selectDayOptions"
                          :reduce="
                            (selectDayOptions) => selectDayOptions.selectDay
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Send this campagin "To", Send this campagin "From" -->
            <div class="vx-col w-full">
              <div class="from-to-camp">
                <div class="vx-row">
                  <!-- Send this campagin "To" -->
                  <div class="vx-col w-full mb-8">
                    <div class="send-camp">
                      <div class="vx-row">
                        <div class="vx-col w-full">
                          <h3>Send this campagin "To"</h3>
                        </div>
                        <div class="vx-col w-full md:w-1/2 md:pr-8">
                          <v-select
                            class="w-full my-2"
                            v-model="campaignTo"
                            label="campaignTo"
                            :options="campaignToOptions"
                            :reduce="
                              (campaignToOptions) =>
                                campaignToOptions.campaignTo
                            "
                          />
                          <v-select
                            v-if="campaignTo == 'Segment'"
                            class="w-full"
                            v-model="segmentId"
                            label="Name"
                            :options="segmentOptions"
                            :reduce="(segmentOptions) => segmentOptions.Id"
                            @input="getSegmentContactCount()"
                          />
                          <v-select
                            v-if="campaignTo == 'Tag'"
                            multiple
                            class="w-full multiple-select"
                            v-model="tagId"
                            label="Tag_Name"
                            :options="tagOptions"
                            :reduce="(tagOptions) => tagOptions.Id"
                           @input="getTagContactCount()"
                          />
                          <div class="add-to-field">
                            <vs-checkbox
                              color="primary"
                              v-model="Addpersonalization"
                              class="m-0 w-full"
                              >Add "To" field personalization</vs-checkbox
                            >
                            <div
                              class="float-left flex items-center w-full flex-wrap mt-3"
                              v-if="Addpersonalization"
                            >
                              <label
                                class="float-left md:w-auto w-full mr-3 md:mb-0 mb-2 pl-10"
                                >Select Merge Tag</label
                              >
                              <v-select
                                class="w-full md:w-1/2"
                                v-model="selectMergeTag"
                                label="Merge_Tag"
                                placeholder="Select Merge Tag"
                                :options="customsFields"
                                :reduce="(customsFields) => customsFields.Id"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="vx-col w-full md:w-1/2 md:pl-8">
                          <p class="cursor-pointer">
                            You are Sending the campagin to (Selected Campaign)
                            with <span @click="getContactFieldDefinition()">{{ contactCount }} Recipents</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Send this campagin "From" -->
                  <div class="vx-col w-full mb-8">
                    <div class="send-camp">
                      <div class="vx-row">
                        <div class="vx-col w-full">
                          <h3>Send this campagin "From"</h3>
                        </div>
                        <div class="vx-col w-full md:w-1/2 md:pr-8">
                          <v-select
                            class="w-full"
                            v-model="campaignFrom"
                            label="From_Name"
                            :options="campaignFromOptions"
                          />
                        </div>
                        <div class="vx-col w-full md:w-1/2 md:pl-8">
                          <p>
                            You are Sending this campagin from <br />
                            {{ campaignFrom ? campaignFrom.From_Name : "" }}
                            {{ campaignFrom ? campaignFrom.From_Email : "" }}
                          </p>
                          <address>
                            {{ campaignFrom ? campaignFrom.Address1 : "" }},
                            <br />
                            {{ campaignFrom ? campaignFrom.City : "" }}
                            {{ campaignFrom ? campaignFrom.Zip : "" }}
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Subject -->
            <div class="vx-col w-full">
              <div class="newsletter-block mt-0">
                <h3>Subject</h3>
                <form class="float-left w-full">
                  <div class="vx-row">
                    <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                      <vs-input
                        class="w-full"
                        label="Subject"
                        placeholder="Subject"
                        name="campaignSubject"
                        v-model="campaignSubject"
                      />
                    </div>
                    <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                      <vs-input
                        class="w-full"
                        label="Preview Text - Preheader"
                        placeholder="Preheader"
                        v-model="previewText"
                        name="previewText"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Design Your Email: Mandatory -->
            <div class="vx-col w-full">
              <div class="newsletter-block">
                <div
                  class="float-left flex items-center justify-between flex-wrap w-full mb-4"
                >
                  <h3 class="w-auto mb-0">Design Your Email</h3>
                  <vs-button
                    color="primary"
                    type="filled"
                    @click="openEmailEditor(-1)"
                    class="small-btn md:mt-0 mt-2"
                    >{{ LabelConstant.buttonLabelEmailDesigner }}</vs-button
                  >
                </div>
                <div class="design-image">
                  <img
                    v-if="!emailThumbnail"
                    src="../../assets/images/post-img.jpg"
                  />
                  <img
                    v-else
                    :src="'data:image/png;base64, ' + emailThumbnail"
                  />
                </div>
                <span class="text-danger text-sm" v-if="emailValidateTemplate"
                  >Please set your email template</span
                >
              </div>
            </div>
            <!-- IF A/B Campaign Design Your Email: Design Your Email -->
            <div v-if="testtype == 'content'" class="vx-col w-full">
              <div class="newsletter-block">
                <div class="vx-row">
                  <div
                    class="vx-col w-full my-2"
                    v-for="(content, index) in Content_List"
                    :key="index"
                  >
                    <div class="float-left flex items-center justify-between flex-wrap w-full mb-4">
                      <h3 class="w-auto mb-0">Design Your Email</h3>
                      <vs-button
                        color="primary"
                        type="filled"
                        @click="openEmailEditor(index)"
                        class="small-btn md:mt-0 mt-2"
                        >{{ LabelConstant.buttonLabelEmailDesigner }}</vs-button
                      >
                    </div>
                    <div class="design-image">
                      <img
                        v-if="!content.Draft_Image"
                        src="../../assets/images/post-img.jpg"
                      />
                      <img
                        v-else
                        :src="'data:image/png;base64, ' + content.Draft_Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- IF A/B Campaign Design Your Email: Subject -->
            <div v-if="testtype == 'subjectline'" class="vx-col w-full">
              <div class="newsletter-block">
                <div class="vx-row">
                  <div class="vx-col w-full"><h3>Subject</h3></div>
                  <div class="vx-col w-full">
                    <div
                      class="vx-row my-3"
                      v-for="(item, index) in Subject_List"
                      :key="index"
                    >
                      <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                        <vs-input
                          class="w-full"
                          label="Subject"
                          placeholder="Subject"
                          name="campaignSubject"
                          v-model="item.Subject"
                        />
                      </div>
                      <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                        <vs-input
                          class="w-full"
                          label="Preview Text - Preheader"
                          placeholder="Preheader"
                          v-model="item.Preview_Text"
                          name="previewText"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- IF A/B Campaign Design Your Email: Send this campagin "From" -->
            <div v-if="testtype == 'from'" class="vx-col w-full">
              <div class="newsletter-block">
                <div
                  class="vx-row mt-6"
                  v-for="(item, index) in Sender_List"
                  :key="index"
                >
                  <div class="vx-col w-full">
                    <h3>Send this campagin "From"</h3>
                  </div>
                  <div class="vx-col w-full md:w-1/2 md:pr-8">
                    <v-select
                      class="w-full"
                      v-model="item.Id"
                      label="From_Name"
                      :options="campaignFromOptions"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 md:pl-8">
                    <p>
                      You are Sending this campagin from <br />
                      {{ item.Id ? item.Id.From_Name : "" }}
                      {{ item.Id ? item.Id.From_Email : "" }}
                    </p>
                    <address>
                      {{ item.Id ? item.Id.Address1 : "" }}, <br />
                      {{ item.Id ? item.Id.City : "" }}
                      {{ item.Id ? item.Id.Zip : "" }}
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Cancel campaign popup -->
      <div class="float-right mt-8 mb-8" v-if="nextTrue">
        <vs-button
          color="grey"
          @click="cancelCampaign()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
      <!-- Email Designer popup -->
      <vs-popup
        :fullscreen="true"
        class="email-editor-popup"
        title="Email Designer"
        :active.sync="emailDesignerCampaign"
      >
        <template>
          <div
            :id="elementDivID"
            class="bee-plugin-container"
            v-if="emailDesignerCampaignBoolean"
          ></div>
        </template>
        <div class="float-right mt-6 mb-8">
          <!-- <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelAdd }}</vs-button>-->
          <vs-button
            color="grey"
            @click="cancelEmailEditor()"
            type="border"
            class="ml-4 grey-btn mr-3"
            >{{ LabelConstant.buttonLabelCancel }}</vs-button
          >
        </div>
      </vs-popup>
    </vs-popup>

    <!-- Delete Campaign Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteCampaignPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Campaign?</h4>
            <span class="deleted-no">{{ deletableCampaignName }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          @click="deleteCampaign(deletableCampaignId)"
          color="danger"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          @click="cancelDeleteCampaign()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Rename Campaign -->
    <vs-popup
      class="tag-info"
      title="Rename Campaign"
      :active.sync="renameCampaignPopupActive"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="vx-row">
              <div class="vx-col w-full mt-6">
                <label>Campaign Name</label>
                <vs-input
                  v-if="selectedCampaignDetail"
                  type="text"
                  class="w-full"
                  v-model="selectedCampaignDetail.Campaign_Name"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="renameCampaign()"
          >{{ LabelConstant.buttonLabelRename }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelRenameCampaign()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Copy Campaign -->
    <vs-popup class="delete-popup" :active.sync="copyCampaignPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Copy this campaign with following new name?</h4>
            <span v-if="selectedCampaignDetail" class="deleted-no">{{
              selectedCampaignDetail.Campaign_Name
            }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="copyCampaign()" color="danger"
          >{{ LabelConstant.buttonLabelCopy }}</vs-button
        >
        <vs-button
          @click="cancelCopyCampaign()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Email Template Popup -->
    <vs-popup
      class="delete-popup"
      :active.sync="deleteEmailTemplatePopupActive"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Email Template?</h4>
            <span class="deleted-no">{{ deletableEmailTemplate }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          @click="deleteSavedEmailTemplate(deletableEmailTemplateId)"
          color="danger"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          @click="cancelDeleteSavedEmailTemplate()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Create Email Template Popup -->
    <vs-popup
      :fullscreen="true"
      class="create-email-popup"
      :title="createEmailTemplateTitle"
      :active.sync="createEditEmailTemplatePopupActive"
    >
      <div class="vx-row mb-6">
        <div class="vx-col lg:w-1/5 mb-4 lg:mb-0 w-full">
          <label class="label-name">Template Name</label>
          <vs-input
            type="text"
            class="w-full"
            v-model="createEditEmailTemplateName"
          />
        </div>
        <div class="vx-col lg:w-1/5 mb-4 lg:mb-0 w-full">
          <label class="label-name">Select Folder</label>
          <v-select
            v-model="createEditEmailTemplateFolder"
            label="Folder_Name"
            placeholder="Select Folder"
            :options="emailTemplateFoldersList"
            :reduce="(option) => option.Id"
          />
        </div>
        <div class="vx-col lg:w-1/5 mb-4 lg:mb-0 w-full">
          <label class="label-name">Template Description</label>
          <vs-input
            type="text"
            class="w-full"
            v-model="createEditEmailTemplateDescription"
          />
        </div>
      </div>
      <template>
        <div
          id="create-update-bee-plugin-container"
          class="bee-plugin-container"
          v-if="createEditEmailTemplatePopupBoolean"
        ></div>
      </template>
      <div class="float-right mt-6 mb-6">
        <vs-button
          color="grey"
          @click="cancelCreateEditEmailTemplateEditor()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}
        </vs-button>
      </div>
    </vs-popup>

    <!-- Add-Edit Email Template Folder -->
    <vs-popup
      class="tag-info"
      :title="addEditFolderPopupTitle"
      :active.sync="addEditFolderPopupActive"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="vx-row">
              <div class="vx-col w-full mt-6">
                <label>Folder Name</label>
                <vs-input
                  type="text"
                  class="w-full"
                  v-model="editableFolderName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          color="primary"
          type="filled"
          @click="addUpdateEmailTemplateFolder()"
          >{{ LabelConstant.buttonLabelSaveFolder }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelAddUpdateEmailTemplateFolder()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Folder Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteFolderPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Folder?</h4>
            <span class="deleted-no">{{ editableFolderName }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteFolder()" color="danger"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          @click="cancelAddUpdateEmailTemplateFolder()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Copy Email Template Popup -->
    <vs-popup class="delete-popup" :active.sync="copyEmailTemplatePopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="WarningIcon"></feather-icon>
            </div>
            <h4>Copy Email Template?</h4>
            <span class="deleted-no" v-if="copyEmailTemplateDetails">{{
              copyEmailTemplateDetails.Template_Name
            }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="copyEmailTemplate()" color="success"
          >{{ LabelConstant.buttonLabelCopy }}</vs-button
        >
        <vs-button
          @click="cancelCopyEmailTemplate()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- View Report Popup -->
    <vs-popup
      class="popup-width close-open"
      title="View Report"
      :active.sync="CampaignViewReportActive"
    >
      <vs-button
        @click="closeViewReport()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <!-- Report Details -->
      <div class="campaign-view-report" v-if="viewReportDetails">
        <vs-tabs class="contact-tab" v-model="tabActive">
          <!-- Overview -->
          <vs-tab label="Overview" icon-pack="feather" icon="icon-eye">
            <!-- Common info -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="customers-info mt-5">
                  <h2>
                    {{ viewReportDetails.Campaign_Name }}
                    <span>{{ viewReportDetails.Contact_Count }} Recipents</span>
                  </h2>
                  <div class="newslatter-data">
                    <ul>
                      <li>
                        Subject: Big News! Lots of New Stuff - Same Great Price!
                      </li>
                      <li>Segment: {{ viewReportDetails.Segment }}</li>
                      <li>
                        Delivered:
                        {{
                          viewReportDetails.Date_Sent_Long
                            | templatEditedDateTime
                        }}
                      </li>
                    </ul>
                    <vs-button
                      class="small-btn bg-actionbutton"
                      type="filled"
                      @click="viewEmailDetails(viewReportDetails.Campaign_Id)"
                      >{{ LabelConstant.buttonLabelViewEmail }}</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Cards Statistics: Sent, Opened, Clicked, Unsubscribed-->
            <div class="vx-row">
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="SendIcon"
                  smallTitle="Sent"
                  bgcolor="bg-first-gradient"
                  maintextcolor="text-first"
                  bgimg="email-marketing.svg"
                  emitTagChange="Sent To"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails ? viewReportDetails.Total_Sent : 0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="ExternalLinkIcon"
                  smallTitle="Opened"
                  bgcolor="bg-second-gradient"
                  maintextcolor="text-second"
                  bgimg="email-marketing.svg"
                  emitTagChange="Opened"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails ? viewReportDetails.Open_Count : 0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="MousePointerIcon"
                  smallTitle="Clicked"
                  bgcolor="bg-third-gradient"
                  maintextcolor="text-third"
                  bgimg="email-marketing.svg"
                  emitTagChange="Clicked"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails ? viewReportDetails.Click_Count : 0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="BellOffIcon"
                  smallTitle="Unsubscribed"
                  bgcolor="bg-fourth-gradient"
                  maintextcolor="text-fourth"
                  bgimg="email-marketing.svg"
                  emitTagChange="Unsubscribed"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails ? viewReportDetails.Unsubscribed_Count : 0"
                >
                </single-card>
              </div>
            </div>
            <!-- 24 hours performance -->
            <div class="vx-row mb-8">
              <div class="vx-col w-full">
                <vx-card>
                  <div class="card-title">
                    <h2>24-hour performance</h2>
                  </div>
                  <vue-apex-charts
                    type="line"
                    height="350"
                    :options="lineChartSimple.chartOptions"
                    :series="lineChartSimple.series"
                  ></vue-apex-charts>
                </vx-card>
              </div>
            </div>
            <!-- Top links clicked and Subscribers with most commons-->
            <div class="vx-row">
              <!-- Top links clicked -->
              <div class="vx-col lg:w-1/2 w-full mb-8">
                <vx-card class="main-list">
                  <div class="card-title"><h2>Top Links clicked</h2></div>
                  <ul class="latest-list">
                    <li v-for="(toplink, index) in viewReportDetails.Top_Links_Clicked" :key="index">
                      <a href="#">{{ toplink.Key }}</a>
                      <span>{{ toplink.Value }}</span>
                    </li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedTopLinks == 5"
                    @click="showMoreTopLinks()"
                    >{{ LabelConstant.buttonLabelShowMore }}</vs-button
                  >
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedTopLinks > 5"
                    @click="showMoreTopLinks()"
                    >{{ LabelConstant.buttonLabelShowLess }}</vs-button
                  >
                </vx-card>
              </div>
              <!-- Subscribers with most commons-->
              <div class="vx-col lg:w-1/2 w-full mb-8">
                <vx-card class="main-list">
                  <div class="card-title">
                    <h2>Subscribers with most opens</h2>
                  </div>
                  <ul class="latest-list">
                    <li v-for="(subscribers, index) in viewReportDetails.Subscribers_With_Most_Open" :key="index">
                      <a href="#">{{ subscribers.Key }}</a>
                      <span>{{ subscribers.Value }}</span>
                    </li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedSubcribers == 5"
                    @click="showMoreSubcriber()"
                    >{{ LabelConstant.buttonLabelShowMore }}</vs-button
                  >
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedSubcribers > 5"
                    @click="showMoreSubcriber()"
                    >{{ LabelConstant.buttonLabelShowLess }}</vs-button
                  >
                </vx-card>
              </div>
            </div>
            <!-- IF A/B Test Result -->
            <div class="vx-row" v-if="viewReportDetails.Campaign_Type == 'AB'">
              <div class="vx-col w-full">
                <div class="card-title pb-2"><h2>A/B Test result</h2></div>
              </div>
              <div
                v-if="senderListReport.length > 0"
                class="vx-col w-full lg:w-1/2"
                v-for="(sender, index) in senderListReport"
                :key="index"
              >
                <div class="test-result">
                  <!-- <span class="letter-box bg-first-gradient">A</span> -->
                  <div class="campaign-result">
                    <h3>Send this campaign "From"</h3>
                    <p>
                      {{
                        sender.Sender_Test.From_Name +
                        " " +
                        sender.Sender_Test.From_Email
                      }}
                    </p>
                    <address>
                      {{ sender.Sender_Test.Address1 }} <br />
                      {{
                        sender.Sender_Test.City +
                        ", " +
                        sender.Sender_Test.State +
                        " " +
                        sender.Sender_Test.Zip
                      }}
                    </address>
                    <div class="campaign-chart vx-row">
                      <div class="w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="sender.Open_Rate_Chart.chartOptions"
                          :series="sender.Open_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Open Rate</h4>
                      </div>
                      <div class="w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="sender.Click_Rate_Chart.chartOptions"
                          :series="sender.Click_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Click Rate</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-full" v-if="subjectListReport.length > 0">
              <div
                class="vx-row w-full mt-3 vx-card sm:p-5 p-4 mr-0"
                v-for="(item, index) in subjectListReport"
                :key="index"
              >
                <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                  <vs-input
                    class="w-full mb-4"
                    label="Subject"
                    placeholder="Subject"
                    name="campaignSubject"
                    v-model="item.Subject_Test.Subject"
                  />
                  <vs-input
                    class="w-full"
                    label="Preview Text - Preheader"
                    placeholder="Preheader"
                    v-model="item.Subject_Test.Preview_Text"
                    name="previewText"
                  />
                </div>

                <div class="vx-col w-full md:mt-0 md:w-1/2">
                  <div class="vx-row text-center pt-3">
                    <div class="vx-col sm:w-1/2">
                      <vue-apex-charts
                        type="radialBar"
                        height="100"
                        :options="item.Open_Rate_Chart.chartOptions"
                        :series="item.Open_Rate_Chart.series"
                      ></vue-apex-charts>
                      <h4>Open Rate</h4>
                    </div>
                    <div class="vx-col sm:w-1/2">
                      <vue-apex-charts
                        type="radialBar"
                        height="100"
                        :options="item.Click_Rate_Chart.chartOptions"
                        :series="item.Click_Rate_Chart.series"
                      ></vue-apex-charts>
                      <h4>Click Rate</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <!-- Activity -->
          <vs-tab label="Activity" icon-pack="feather" icon="icon-activity">
            <div class="tab-text">
              <!-- Common info -->
              <div class="customers-info mt-5">
                <h2>
                  {{ viewReportDetails.Campaign_Name }}
                  <span>{{ viewReportDetails.Contact_Count }}</span>
                </h2>
                <div class="newslatter-data">
                  <ul>
                    <li>Subject: {{ viewReportDetails.Subject }}</li>
                    <li>Segment: {{ viewReportDetails.Segment }}</li>
                    <li>
                      Delivered:
                      {{
                        viewReportDetails.Date_Sent_Long | templatEditedDateTime
                      }}
                    </li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    @click="viewEmailDetails(viewReportDetails.Campaign_Id)"
                    >{{ LabelConstant.buttonLabelViewEmail }}</vs-button
                  >
                </div>
              </div>
              <!-- Contacts table -->
              <div class="contacts-info">
                <div class="contact-table">
                  <div class="filter">
                    <div class="filter-option">
                      <v-select
                        label="filterName"
                        placeholder="Filter By sent To"
                        :options="filterByOption"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterBy"
                      />
                      <v-select
                        v-if="filterBy && filterBy.filterName == 'Clicked specific Link'"
                        label="Link_Url"
                        placeholder="Select Link"
                        v-model="linkSelected"
                        :options="linksummaryData"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        @input="getFilterContact(viewReportDetails.Campaign_Id)"
                      />
                    </div>
                    <vs-button
                      radius
                      type="filled"
                      class="bg-actionbutton small-btn"
                      @click="gridOptions.api.exportDataAsCsv()"
                      >{{ LabelConstant.buttonLabelExportContact }}</vs-button
                    >
                  </div>
                  <div class="float-left w-full">
                    <div
                      class="flex flex-wrap justify-between items-center w-full"
                    >
                      <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                          <div
                            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          >
                            <span class="mr-2"
                              >{{
                                currentPage * paginationPageSize -
                                (paginationPageSize - 1)
                              }}
                              -
                              {{
                                filterContacts.length -
                                  currentPage * paginationPageSize >
                                0
                                  ? currentPage * paginationPageSize
                                  : filterContacts.length
                              }}
                              of {{ filterContacts.length }}</span
                            >
                            <feather-icon
                              icon="ChevronDownIcon"
                              svgClasses="h-4 w-4"
                            />
                          </div>
                          <vs-dropdown-menu>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(5)"
                            >
                              <span>5</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(10)"
                            >
                              <span>10</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(20)"
                            >
                              <span>20</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(25)"
                            >
                              <span>25</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(50)"
                            >
                              <span>50</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(100)"
                            >
                              <span>100</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(200)"
                            >
                              <span>200</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(300)"
                            >
                              <span>300</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(400)"
                            >
                              <span>400</span>
                            </vs-dropdown-item>
                            <vs-dropdown-item
                              @click="gridApi.paginationSetPageSize(500)"
                            >
                              <span>500</span>
                            </vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </div>
                      <div
                        class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                      >
                        <vs-input
                          class="mb-4 md:mb-0 mr-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                    <ag-grid-vue
                      ref="agGridTable"
                      :gridOptions="gridOptions"
                      class="ag-theme-material w-100 my-4 ag-grid-table"
                      :columnDefs="FiltercolumnDefs"
                      :defaultColDef="defaultColDef"
                      :rowData="filterContacts"
                      rowSelection="multiple"
                      colResizeDefault="shift"
                      :animateRows="true"
                      :floatingFilter="true"
                      :pagination="true"
                      :paginationPageSize="5"
                      :suppressPaginationPanel="true"
                      :frameworkComponents="frameworkComponents"
                      :context="context"
                      :gridReady="onGridReady()"
                      :enableRtl="$vs.rtl"
                    >
                    </ag-grid-vue>
                    <vs-pagination
                      class="ag-grid-pagination-area"
                      :total="totalPages"
                      :max="maxPageNumbers"
                      v-model="currentPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <!-- Link Summary -->
          <vs-tab label="Link Summary" icon-pack="feather" icon="icon-link">
            <!-- URL Table -->
            <div class="tab-text mt-5">
              <vs-table class="summary-table" :data="linksummaryData" search>
                <template slot="thead">
                  <vs-th sort-key="url">URL</vs-th>
                  <vs-th sort-key="totalclicks" width="120" align="right"
                    >Total Clicks</vs-th
                  >
                  <vs-th sort-key="uniqueclicks" width="150" align="right"
                    >Unique Clicks</vs-th
                  >
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.Link_Url">{{ tr.Link_Url }}</vs-td>
                    <vs-td align="right" :data="tr.Click_Count">{{
                      tr.Click_Count + "(" + tr.Click_Rate + "%)"
                    }}</vs-td>
                    <vs-td align="right" :data="tr.Unique_Click_Count">{{
                      tr.Unique_Click_Count + "(" + tr.Unique_Click_Rate + "%)"
                    }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <div class="float-right mt-6 mb-8">
        <vs-button
          @click="closeViewReport()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<!-- Script -->
<script>
var joinAddresses = function (params) {
  return params.data.Address1 + ", " + params.data.Address2;
};
var joinTags = function (params) {
  let str = "";
  params.data.Tags.forEach((el) => {
    str += '<span class="badge badge-success ">' + el.Tag_Name + "</span>";
  });
  return str;
};
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { Validator } from "vee-validate";
import moment from "moment";
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import SingleCard from "../../components/SingleCard";
//import moduleCalendar from "@/store/calendar/moduleCalendar.js";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "flatpickr/dist/flatpickr.css";
import VueJwtDecode from "vue-jwt-decode";

import Vue from "vue";
import JwPagination from "jw-vue-pagination";
import Pagination from "../../components/pagination/PaginationLinksLimit";

import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import ContactDetailsLink from "@/components/email-marketing/ContactDetailsLink";

import "vue-range-component/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component";
// import RadialSmallChart from '../../components/charts/RadialSmallChart.vue';

Vue.component("jw-pagination", JwPagination);
Vue.component("Pagination", Pagination);

// const exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

const dict = {
  custom: {
    campaignName: {
      required: "Please enter campaign name",
    },
    Schedule_Date: {
      required: "Please select schedule date",
    },
    Schedule_Time: {
      required: "Please select schedule date",
    },
  },
};
require("vue-simple-calendar/static/css/default.css");
Validator.localize("en", dict);
export default {
  components: {
    FullCalendar,
    VueJwtDecode,
    VxCard,
    vSelect,
    VueApexCharts,
    flatPickr,
    SingleCard,
    CalendarView,
    CalendarViewHeader,
    Pagination,
    AgGridVue,
    ContactDetailsLink,
    VueRangeSlider,
    // RadialSmallChart,
  },
  data() {
    return {
      warningMessage:"",
      warningMessagePopup:false,
      contactsData:[],
      contactDetailsActive:false,
      contactCount:0,
      emailValidateTemplate: false,
      filterBy: null,
      searchQuery: null,
      maxPageNumbers: 7,
      context: null,
      frameworkComponents: null,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs:[],
      FiltercolumnDefs: [
        {
          headerName: "Email Address",
          field: "Email_Address",
          width: 200,
          pinned: "left",
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellRenderer: "contactDetailsLink",
        },
        {
          headerName: "Company",
          field: "Company",
          filter: true,
          width: 250,
        },
        {
          headerName: "First Name",
          field: "First_Name",
          filter: true,
          width: 250,
        },
        {
          headerName: "Last Name",
          field: "Last_Name",
          filter: true,
          width: 250,
        },
        {
          headerName: "Address",
          valueGetter: joinAddresses,
          filter: true,
          width: 350,
        },
        {
          headerName: "Tags",
          field: "Tags",
          filter: false,
          width: 350,
          cellRenderer: joinTags,
        },
        {
          headerName: "Email Status",
          field: "Email_Status",
          filter: true,
          width: 350,
        },
      ],

      filterContacts: [],
      tabActive: 0,
      tempSavedEmailTemplatesListStore: null,
      filterEmailTemplateFolder: null,
      preBuiltTemplates: [],
      limitedTopLinks: 5,
      limitedSubcribers: 5,
      senderListReport: [],
      subjectListReport: [],
      pageOfItems: [],
      campaignsNameArray: [],
      emailTemplateType: null,
      emailTemplateSelected: null,
      mainActivetab: 1,
      campaignsListByClassification: [],
      numberOfCampaignsInLastSevenDays: 0,
      numberOfCampaignsInLastThirtyDays: 0,
      numberOfCampaignsInOther: 0,
      emailText: "",
      configOptions: {
        events: [],
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        dayMaxEvents: true,
        eventClick: this.openEditEvent,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
      },
      viewReportDetails: null,
      CampaignViewReportActive: false,
      emailTemplateSortingOrder: null,
      emailTemplateSortByOptions: [
        {
          key: "ASC",
          orderBy: "Template_Name",
          orderName: "Ascending",
        },
        {
          key: "DESC",
          orderBy: "Template_Name",
          orderName: "Descending",
        },
        {
          key: "DESC",
          orderBy: "sortableDate",
          orderName: "Newest First",
        },
        {
          key: "ASC",
          orderBy: "sortableDate",
          orderName: "Oldest First",
        },
      ],
      copyEmailTemplatePopupActive: false,
      copyEmailTemplateDetails: null,
      viewEmailTemplate: false,
      deleteFolderPopupActive: false,
      editFolderMode: false,
      addEditFolderPopupTitle: null,
      addEditFolderPopupActive: false,
      editableFolderId: 0,
      editableFolderName: null,
      editableFolderItemCount: "0",

      viewDetails: null,
      eventData: [],
      editEmailTemplateMode: false,
      createEmailTemplateTitle: null,
      createEditEmailTemplatePopupActive: false,
      createEditEmailTemplatePopupBoolean: false,
      createEditEmailTemplateContent: null,
      createEditEmailTemplateImage: null,
      createEditEmailTemplateName: null,
      createEditEmailTemplateDescription: null,
      createEditEmailTemplateFolder: null,
      createEditEmailTemplateId: 0,
      emailTemplateFoldersList: [],

      selectedFolderForSearching: null,
      tempEmailTemplatesStore: null,
      emailTemplateSearchTerm: null,
      savedEmailTemplatesList: null,
      deleteEmailTemplatePopupActive: false,
      deletableEmailTemplate: null,
      deletableEmailTemplateId: null,

      tempCampaignsStore: null,
      copyCampaignPopupActive: false,
      renameCampaignPopupActive: false,
      selectedCampaignDetail: null,
      IsScheduled: false,
      deletableCampaignName: null,
      deletableCampaignId: null,
      deleteCampaignPopupActive: false,
      emailDesignerCampaignBoolean: false,
      letEMailToken: null,
      emailContent: null,
      emailThumbnail: null,
      emailDesignerIndex: null,
      emailDesignerCampaign: false,
      campaignReadOnly: true,
      nextTrue: false,
      campaignId: 0,
      campaignType: "Regular",
      activeTab: 1,
      newsDataTable: [],
      calendarView: "month",
      tofield: "",
      Addpersonalization: true,
      campaignSubject: "",
      previewText: "",
      Percent_To_Test_To_Pick_Winner: 10,
      //campaign
      campaigns: [],
      tagId: [],
      segmentId: 0,
      // Calendar
      showDate: new Date(),
      activePromptAddEvent: false,
      activePromptEditEvent: false,
      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      date: null,
      time: null,

      // Campaign
      searchTerm: null,
      createCampaignActive: false,
      addScheduleActive: false,
      order: null,
      orderByOptions: [
        {
          key: "Campaign_Name",
          orderBy: "Campaign Name",
        },
        {
          key: "Date_Created",
          orderBy: "Date Created",
        },
        {
          key: "Date_Updated",
          orderBy: "Date Edited",
        },
      ],
      configdateTimePicker: {
        minDate: new Date(),
      },
      filterByType: { campaignType: "All" },
      TypeOptions: [
        {
          campaignType: "All",
        },
        {
          campaignType: "Regular",
        },
        {
          campaignType: "AB",
        },
        {
          campaignType: "Text",
        },
      ],
      draftOptions: [
        {
          draftName: "Edit",
        },
        {
          draftName: "Copy this Campaign",
        },
        {
          draftName: "Delete",
        },
      ],
      sentOptions: [
        {
          draftName: "Copy this Campaign",
        },
        {
          draftName: "View Report",
        },
        {
          draftName: "Rename",
        },
        {
          draftName: "Delete",
        },
      ],
      Sender_List: null,
      Subject_List: null,
      Content_List: null,
      Schedule_Date: null,
      Schedule_Time: null,
      testtype: null,
      numberVariations: null,
      variationsOptions: [
        {
          variationsName: 2,
        },
        {
          variationsName: 3,
        },
        {
          variationsName: 4,
        },
      ],
      Winner_Determining_Factor: null,
      rateOptions: [
        {
          rateLabel: "Open Rate",
        },
        {
          rateLabel: "Click Rate",
        },
      ],
      Winner_Determining_After_Value: 0,
      Winner_Determining_After_Type: null,
      ratenumber: [],
      rateNumberOptions: [
        {
          rateNumber: "1",
        },
        {
          rateNumber: "2",
        },
      ],
      daytime: null,
      selectDayOptions: [
        {
          selectDay: "Hours",
        },
        {
          selectDay: "Days",
        },
      ],
      segmentOptions: [],
      tagOptions: [],
      campaignTo: null,
      campaignFrom: null,
      campaignFromOptions: [],
      campaignToOptions: [
        {
          campaignTo: "Segment",
        },
        {
          campaignTo: "Tag",
        },
      ],
      selectMergeTag: null,
      emailDesignActive: false,
      searchSavedTemp: null,
      tempPreBuiltTemplate: null,
      prebuiltSearch: null,
      sortASC: true,
      sortBySelected: null,
      sortByOptions: [
        {
          key: "ED",
          sortBy: "Edited Date",
        },
        {
          key: "CD",
          sortBy: "Created Date",
        },
        {
          key: "TN",
          sortBy: "Template Name",
        },
      ],

      // Campaign View Report
      /* Overview */
      lineChartSimple: {
        series: [
          {
            name: "Opens",
            data: [35, 8, 12, 7, 5, 2, 1],
          },
          {
            name: "Clicks",
            data: [0, 10, 5, 7, 3, 2, 1],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false,
            },
          },
          colors: ["#044d80", "#ecb940"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [
              "1.00PM",
              "5.00PM",
              "9.00PM",
              "1.00AM",
              "5.00AM",
              "9.00AM",
            ],
          },
        },
      },
      radialBarChart: {
        series: [44],
        chartOptions: {
          colors: ["#7AC546"],
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                  show: false,
                },
                value: {
                  fontSize: "16px",
                  offsetY: 6,
                },
                total: {
                  show: false,
                },
              },
            },
          },
        },
      },
      campaignName: "",
      elementDivID: "bee-plugin-container",
      /* Activity */
      filterByOption: [
        {
          filterName: "Sent To",
        },
        {
          filterName: "Opened",
        },
        {
          filterName: "Didn't Open",
        },
        {
          filterName: "Clicked",
        },
        {
          filterName: "Bonuced",
        },
        {
          filterName: "Unsubscribed",
        },
        {
          filterName: "Complained",
        },
        {
          filterName: "Clicked specific Link",
        },
      ],
      selectLinkOption: [
        {
          selectLink: "Sent To",
        },
        {
          selectLink: "Opened",
        },
        {
          selectLink: "Didn't Open",
        },
      ],
      activitylist: [],
      activityListData: [
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
      ],
      /* Link Summary */
      linksummary: [],
      linkSelected: null,
      linksummaryData: [],
      customsFields: [],
      perPage: 5,
      recordList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };
  },
  watch: {
    filterBy(val) {
      if (!val && this.viewReportDetails) {
        this.linkSelected = null;
        this.getFilterContact(this.viewReportDetails.Campaign_Id);
      } else if (val && val.filterName != "Clicked specific Link") {
        this.getFilterContact(this.viewReportDetails.Campaign_Id);
      }
    },
    prebuiltSearch(val) {
      if (val) {
        if (!this.tempPreBuiltTemplate) {
          this.tempPreBuiltTemplate = this.preBuiltTemplates;
        }
        this.preBuiltTemplates = this.tempPreBuiltTemplate;
        var resultObject = this.searchInTemplates(val, this.preBuiltTemplates);
        this.preBuiltTemplates = resultObject;
      } else {
        this.preBuiltTemplates = this.tempPreBuiltTemplate;
        this.tempPreBuiltTemplate = null;
      }
    },
    // searchSavedTemp(val) {
    //   if (val) {
    //     if (
    //       this.tempSavedEmailTemplatesListStore &&
    //       !this.filterEmailTemplateFolder
    //     ) {
    //       this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
    //     } else if (!this.tempSavedEmailTemplatesListStore) {
    //       this.tempSavedEmailTemplatesListStore = this.savedEmailTemplatesList;
    //     }

    //     var resultObject = this.searchInTemplates(
    //       val,
    //       this.savedEmailTemplatesList
    //     );
    //     this.savedEmailTemplatesList = resultObject;
    //   } else {
    //     this.refreshSavedTemplateListAfterSearch();
    //   }
    // },

    //sort templates selected by the parameter 
    sortBySelected(val) {
      if (val) {
        if (val.key == "ED") {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Date_Updated", this.sortASC ? "ASC" : "DESC")
          );
        } else if (val.key == "TN") {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Template_Name", this.sortASC ? "ASC" : "DESC")
          );
        } else {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Date_Created", this.sortASC ? "ASC" : "DESC")
          );
        }
      }
    },
    //sort templates 
    sortASC(val) {
      if (this.sortBySelected) {
        if (this.sortBySelected.key == "ED") {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Date_Updated", val ? "ASC" : "DESC")
          );
        } else if (this.sortBySelected.key == "TN") {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Template_Name", val ? "ASC" : "DESC")
          );
        } else {
          this.savedEmailTemplatesList.sort(
            this.GetSortOrder("Date_Created", this.sortASC ? "ASC" : "DESC")
          );
        }
      }
    },
    // add persnalization parameter
    Addpersonalization(val) {
      if (!val) {
        this.selectMergeTag = null;
      }
    },
    // campaign value change and empty other
    campaignTo(val) {
      if (val != null && val == "Segment") {
        this.tagId = [];
      } else if (val != null && val == "Tag") {
        this.segmentId = 0;
      } else {
        this.tagId = [];
        this.segmentId = 0;
      }
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    paginationPageSize() {
      if (this.gridOptions.api)
        return this.gridOptions.api.paginationGetPageSize();
      else return 1;
    },
    totalPages() {
      if (this.gridOptions.api)
        return this.gridOptions.api.paginationGetTotalPages();
      else return 1;
    },
    currentPage: {
      get() {
        if (this.gridOptions.api)
          return this.gridOptions.api.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridOptions.api.paginationGoToPage(val - 1);
      },
    },
  },
  filters: {
    templatEditedDateTime: function (value) {
      return moment(value).format("MMMM DD, YYYY HH:mm A");
    },
  },
  beforeMount() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      contactDetailsLink: ContactDetailsLink,
    };
  },
  created() {
    this.tooltipMerge = false;
    this.formatter = (value) => `${value}%`;

    this.getCampaignList();
    this.getListSender();
    this.getSegmentList();
    this.getTagList();
    this.getCustomField();
    this.listSavedEmailTemplates();
    this.listEmailTemplateFolders();
    this.getEmailPreBuiltTemplate();
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector(
          ".ag-header-container"
        );
        header.style.left =
          "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    // on change tab with filter set on page
    changeTab(val) {
      this.tabActive = 1;
      this.filterBy = { filterName: val };
    },
    // cancel template selection
    cancelTemplateSelection() {
      if (this.emailDesignerIndex != -1) {
        this.Content_List.forEach((ele, i) => {
          if (this.emailDesignerIndex == i) {
            ele.Draft_Json = "";
          }
        });
      } else {
        this.emailContent = null;
      }
      this.sortASC = true;
      this.prebuiltSearch = null;
      this.sortBySelected = null;
      this.emailDesignActive = false;
      this.emailTemplateSelected = null;
      this.emailContent = null;
      this.emailTemplateType = null;
      this.searchSavedTemp = null;
      this.filterEmailTemplateFolder = null;
      if (this.tempSavedEmailTemplatesListStore) {
        this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
      }
      this.tempSavedEmailTemplatesListStore = null;
    },
    filterTemplate() {
      if (!this.tempSavedEmailTemplatesListStore) {
        this.tempSavedEmailTemplatesListStore = this.savedEmailTemplatesList;
      }
      this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
      if (this.searchSavedTemp && this.filterEmailTemplateFolder) {
        let newarr = this.savedEmailTemplatesList.filter((el) => {
          return el.Folder_Id == this.filterEmailTemplateFolder;
        });
        let resultObject = this.searchInTemplates(this.searchSavedTemp, newarr);
        this.savedEmailTemplatesList = resultObject;
      } else if (this.searchSavedTemp && !this.filterEmailTemplateFolder) {
        let resultObject = this.searchInTemplates(
          this.searchSavedTemp,
          this.savedEmailTemplatesList
        );
        this.savedEmailTemplatesList = resultObject;
      } else if (!this.searchSavedTemp && this.filterEmailTemplateFolder) {
        let newarr = this.savedEmailTemplatesList.filter((el) => {
          return el.Folder_Id == this.filterEmailTemplateFolder;
        });
        this.savedEmailTemplatesList = newarr;
      } else {
        this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
      }
    },
    /*filterTemplateWithFolders() {
      if (!this.tempSavedEmailTemplatesListStore) {
        this.tempSavedEmailTemplatesListStore = this.savedEmailTemplatesList;
      }
      if (this.filterEmailTemplateFolder) {
        if (!this.searchSavedTemp) {
          this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
        }

        let newarr = this.savedEmailTemplatesList.filter((el) => {
          return el.Folder_Id == this.filterEmailTemplateFolder;
        });
        this.savedEmailTemplatesList = newarr;
      } else {
        if (!this.searchSavedTemp) {
          this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
        } else {
          this.searchSavedTemp();
        }
      }
    },*/
    searchInTemplates(nameKey, myArray) {
      var arrrr = [];
      nameKey = nameKey.toLowerCase();

      for (var i = 0; i < myArray.length; i++) {
        // if (myArray[i].Campaign_Name === nameKey) {
        //     arrrr.push(myArray[i]);
        // }
        if (myArray[i].Template_Name.toLowerCase().search(nameKey) != -1) {
          arrrr.push(myArray[i]);
        }
      }
      return arrrr;
    },
    /*refreshSavedTemplateListAfterSearch() {
      if (!this.filterEmailTemplateFolder) {
        this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
        this.tempSavedEmailTemplatesListStore = null;
      } else {
        this.filterTemplateWithFolders();
      }
    },*/
    selectTemplate(type, index, json) {
      this.emailTemplateSelected = index;
      this.emailTemplateType = type;
      if (this.emailDesignerIndex == -1) {
        this.emailContent = json;
      } else {
        this.Content_List.forEach((ele, i) => {
          if (this.emailDesignerIndex == i) {
            ele.Draft_Json = json;
          }
        });
      }
    },
    ShowEmailBuilder() {
      this.sortASC = true;
      this.sortBySelected = null;
      this.prebuiltSearch = null;
      this.searchSavedTemp = null;
      this.filterEmailTemplateFolder = null;
      this.emailDesignActive = false;
      this.emailTemplateSelected = null;
      this.emailTemplateType = null;
      this.searchSavedTemp = null;
      this.filterEmailTemplateFolder = null;
      this.emailDesignerCampaignBoolean = true;
      if (this.tempSavedEmailTemplatesListStore) {
        this.savedEmailTemplatesList = this.tempSavedEmailTemplatesListStore;
      }
      this.tempSavedEmailTemplatesListStore = null;
      this.authorizeBeeFreePlugin();
    },
    showMoreTopLinks() {
      if (this.limitedTopLinks == 5) {
        this.limitedTopLinks = this.viewReportDetails.Top_Links_Clicked.length;
      } else {
        this.limitedTopLinks = 5;
      }
    },
    showMoreSubcriber() {
      if (this.limitedSubcribers == 5) {
        this.limitedSubcribers = this.viewReportDetails.Subscribers_With_Most_Open.length;
      } else {
        this.limitedSubcribers = 5;
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    cancelViewEmailDetails() {
      this.emailThumbnail = null;
      this.selectedCampaignDetail = null;
      this.viewEmailTemplate = false;
      this.emailText = "";
    },
    viewEmailDetails(id) {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaign/GetCampaign?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.selectedCampaignDetail = data;
          if (this.selectedCampaignDetail.Content) {
            if (this.selectedCampaignDetail.Thumbnail_Image) {
              this.emailThumbnail = this.selectedCampaignDetail.Thumbnail_Image;
            } else {
              this.getBeeFreeGetThumbnail(
                this.selectedCampaignDetail.Content,
                null,
                -1
              );
            }
          } else {
            this.emailText = "Email template not designed";
          }
          this.viewEmailTemplate = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    viewDateFormat(val) {
      // return moment(val).format('ddd, MMMM Do h:mm A');
      return moment(val).format("llll");
    },
    changeNumberOrType(changetype1) {
      if (this.CampaignId == 0) {
        if (this.numberVariations != null) {
          if (this.testtype == "from") {
            this.Sender_List = [];
            for (let i = 0; i < this.numberVariations; i++) {
              this.Sender_List.push({ Id: 0 });
            }
            this.Subject_List = null;
            this.Content_List = null;
          } else if (this.testtype == "subjectline") {
            this.Subject_List = [];
            for (let i = 0; i < this.numberVariations; i++) {
              this.Subject_List.push({ Id: 0, Subject: "", Preview_Text: "" });
            }

            this.Sender_List = null;
            this.Content_List = null;
          } else {
            this.Content_List = [];
            for (let i = 0; i < this.numberVariations; i++) {
              this.Content_List.push({
                Id: 0,
                Draft_Name: "",
                Draft_Image: "",
                Draft_Json: "",
              });
            }
            this.Sender_List = null;
            this.Subject_List = null;
          }
        }
      } else {
        if (changetype1 == "number") {
          if (this.numberVariations != null) {
            if (this.testtype == "from") {
              this.Sender_List = [];
              for (let i = 0; i < this.numberVariations; i++) {
                this.Sender_List.push({ Id: 0 });
              }
              this.Subject_List = null;
              this.Content_List = null;
            } else if (this.testtype == "subjectline") {
              this.Subject_List = [];
              for (let i = 0; i < this.numberVariations; i++) {
                this.Subject_List.push({
                  Id: 0,
                  Subject: "",
                  Preview_Text: "",
                });
              }
              this.Sender_List = null;
              this.Content_List = null;
            } else {
              this.Content_List = [];
              for (let i = 0; i < this.numberVariations; i++) {
                this.Content_List.push({
                  Id: 0,
                  Draft_Name: "",
                  Draft_Image: "",
                  Draft_Json: "",
                });
              }
              this.Sender_List = null;
              this.Subject_List = null;
            }
          }
        } else {
          if (this.campaignId != 0) {
            if (this.testtype == "from") {
              if (this.Sender_List == null) {
                this.Sender_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Sender_List.push({ Id: 0 });
                }
              }
            } else if (this.testtype == "subjectline") {
              if (this.Subject_List == null) {
                this.Subject_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Subject_List.push({
                    Id: 0,
                    Subject: "",
                    Preview_Text: "",
                  });
                }
              }
            } else {
              if (this.Content_List == null) {
                this.Content_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Content_List.push({
                    Id: 0,
                    Draft_Name: "",
                    Draft_Image: "",
                    Draft_Json: "",
                  });
                }
              }
            }
          } else {
            if (this.numberVariations != null) {
              if (this.testtype == "from") {
                this.Sender_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Sender_List.push({ Id: 0 });
                }
                this.Subject_List = null;
                this.Content_List = null;
              } else if (this.testtype == "subjectline") {
                this.Subject_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Subject_List.push({
                    Id: 0,
                    Subject: "",
                    Preview_Text: "",
                  });
                }

                this.Sender_List = null;
                this.Content_List = null;
              } else {
                this.Content_List = [];
                for (let i = 0; i < this.numberVariations; i++) {
                  this.Content_List.push({
                    Id: 0,
                    Draft_Name: "",
                    Draft_Image: "",
                    Draft_Json: "",
                  });
                }
                this.Sender_List = null;
                this.Subject_List = null;
              }
            }
          }
        }
      }
    },
    sortCampaign() {
      this.campaigns.sort(this.GetSortOrder(this.order, "ASC"));
    },
    scheduleCancel() {
      if (this.campaignId == 0) {
        this.addScheduleActive = false;
        this.Schedule_Date = null;
        this.Schedule_Time = null;
      } else {
        if (this.IsScheduled) {
          this.addScheduleActive = false;
        } else {
          this.addScheduleActive = false;
          this.Schedule_Date = null;
          this.Schedule_Time = null;
        }
      }
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    nextStep() {
      this.$validator.validateAll("campaignvalidate").then(async (result) => {
        if (result) {
          this.nextTrue = true;
        }
      });
    },
    openEditEvent(data) {
      this.viewDetails = this.campaigns.find((ele) => ele.Id == data.event.id);
      this.activePromptAddEvent = true;
    },
    eventDragged() {},
    getWeekDates() {
      let now = new Date();
      let dayOfWeek = now.getDay(); //0-6
      let numDay = now.getDate();

      let start = new Date(now); //copy
      start.setDate(numDay - dayOfWeek);
      start.setHours(0, 0, 0, 0);

      let end = new Date(now); //copy
      end.setDate(numDay + (7 - dayOfWeek));
      end.setHours(0, 0, 0, 0);

      return [start, end];
    },
    checkDate(checkfrom, checkto, dtcheck) {
      //   var dateFrom = "03/01/2020";
      //   var dateTo = "10/01/2020";
      //   var dateCheck = "10/01/2020";
      var dateFrom = checkfrom;
      var dateTo = checkto;
      var dateCheck = dtcheck;
      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");

      var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
      var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

      return "checkdate", check >= from && check <= to;
    },
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    clearFields() {
      this.title = this.endDate = this.url = "";
      this.id = 0;
      this.labelLocal = "none";
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.startDate = date;
      this.endDate = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    formateDate(dt) {
      return moment(dt).format("MMMM Do hh:mm A");
    },
    makeFirstLetterCaps(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    isDateWithinLastSevenDays(date) {
      var sevenDaysBackDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      var dateTobeTest = new Date(date);
      if (dateTobeTest >= sevenDaysBackDate) {
        return true;
      } else {
        return false;
      }
    },
    isDateWithinLastThirtyDays(date) {
      var sevenDaysBackDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      var dateTobeTest = new Date(date);
      if (dateTobeTest >= sevenDaysBackDate) {
        return true;
      } else {
        return false;
      }
    },
    async getCampaignList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingCampaign/ListCampaignSummaryVersion")
        .then((response) => {
          //let [start, end] = this.getWeekDates();
          this.eventData = [];
          let data = response.data;
          data.sort(this.GetSortOrder("Date_Updated", "DESC"));
          this.campaignsNameArray = [];
          data.forEach((e) => {
            this.campaignsNameArray.push(e.Campaign_Name);
            if (
              e.Campaign_Status == "Scheduled" ||
              e.Campaign_Status == "Sent"
            ) {
              this.eventData.push({
                id: e.Id,
                date: moment(e.Date_Sent_Long).format("YYYY-MM-DD"),
                backgroundColor:
                  e.Campaign_Status == "Scheduled" ? "#FF9F43" : "#7ac546",
                borderColor:
                  e.Campaign_Status == "Scheduled" ? "#FF9F43" : "#7ac546",
                // classes:
                //   e.Campaign_Status == "Scheduled"
                //     ? "event-warning"
                //     : "event-success",
                // label: e.Campaign_Status == "Scheduled" ? "Scheduled" : "Sent",
                // startDate: moment(e.Date_Sent_Long).format("YYYY-MM-DD"),
                // endDate: moment(e.Date_Sent_Long).format("YYYY-MM-DD"),
                title: e.Campaign_Name,
                allDay: true,
              });
            }
            //        let checkDt = this.checkDate(start,end,e.Date_Sent_Short);
            //      console.log(checkDt);
            e.Action = "";
            e.Open_Rate_Chart = {
              series: [e.Open_Rate],
              chartOptions: {
                colors: ["#7AC546"],
                plotOptions: {
                  radialBar: {
                    dataLabels: {
                      name: {
                        fontSize: "22px",
                        show: false,
                      },
                      value: {
                        fontSize: "16px",
                        offsetY: 6,
                      },
                      total: {
                        show: false,
                      },
                    },
                  },
                },
              },
            };
            e.Click_Rate_Chart = {
              series: [e.Click_Rate],
              chartOptions: {
                colors: ["#7AC546"],
                plotOptions: {
                  radialBar: {
                    dataLabels: {
                      name: {
                        fontSize: "22px",
                        show: false,
                      },
                      value: {
                        fontSize: "16px",
                        offsetY: 6,
                      },
                      total: {
                        show: false,
                      },
                    },
                  },
                },
              },
            };
          });
          this.configOptions.events = this.eventData;
          this.campaigns = data;

          this.campaignsListByClassification = [[], [], []];
          this.numberOfCampaignsInLastSevenDays = 0;
          this.numberOfCampaignsInLastThirtyDays = 0;
          this.numberOfCampaignsInOther = 0;
          for (let i = 0; i < data.length; i++) {
            if (this.isDateWithinLastSevenDays(data[i].Date_Updated)) {
              this.campaignsListByClassification[0].push(data[i]);
              this.numberOfCampaignsInLastSevenDays++;
            } else if (this.isDateWithinLastThirtyDays(data[i].Date_Updated)) {
              this.campaignsListByClassification[1].push(data[i]);
              this.numberOfCampaignsInLastThirtyDays++;
            } else {
              this.campaignsListByClassification[2].push(data[i]);
              this.numberOfCampaignsInOther++;
            }
          }
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getListSender() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsSender/ListSenders")
        .then((response) => {
          let data = response.data;
          this.campaignFromOptions = data;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    scheduleValidate() {
      this.$validator.validateAll("scheduleform").then(async (result) => {
        if (result) {
          if (!this.emailContent && !this.emailThumbnail) {
            this.addScheduleActive = false;
            this.emailValidateTemplate = true;
          } else {
            this.addScheduleActive = false;
            this.saveCampaigns("schedule");
          }
        }
      });
    },
    async saveCampaigns(status) {
      if (!this.emailThumbnail && !this.emailContent) {
        this.emailValidateTemplate = true;
        return;
      }
      if (this.testtype == "subjectline") {
        this.Content_List = null;
        this.Sender_List = null;
      } else if (this.testtype == "from") {
        this.Content_List = null;
        this.Subject_List = null;
      } else {
        this.Sender_List = null;
        this.Subject_List = null;
      }
      let fromList = null;
      if (this.Sender_List) {
        fromList = [];
        this.Sender_List.forEach((e) => {
          fromList.push(e.Id);
        });
      }

      let scheduleDate = null;
      let draft = false;
      let schedule = false;
      let sent = false;
      if (status == "draft") {
        this.Schedule_Date = null;
        this.Schedule_Time = null;
        draft = true;
        schedule = false;
        sent = false;
      } else if (status == "schedule") {
        scheduleDate = this.Schedule_Date + " " + this.Schedule_Time;
        // if (this.Schedule_Date == null || this.Schedule_Time == null) {
        //   this.addScheduleActive = true;
        //   return;
        // } else {
        // }
        draft = false;
        schedule = true;
        sent = false;
      } else {
        this.Schedule_Date = null;
        this.Schedule_Time = null;
        draft = false;
        schedule = true;
        sent = false;
      }
      this.$vs.loading();
      let input = {
        Id: this.campaignId,
        Campaign_Name: this.campaignName,
        Campaign_Type: this.campaignType,
        Send_To_Type: this.campaignTo,
        Send_To_Personalization: this.Addpersonalization,
        Send_To_Tag_Ids: this.tagId,
        Send_To_Segment_Id: this.segmentId,
        Send_To_Merge_Tag_Id: this.selectMergeTag ? this.selectMergeTag : 0,
        Send_From_Id: this.campaignFrom ? this.campaignFrom.Id : 0,
        Subject: this.campaignSubject,
        Subject_Preview: this.previewText,
        Content: this.emailContent,
        HasBeenSent: sent,
        IsScheduled: schedule,
        IsDraft: draft,
        Schedule_Date: this.Schedule_Date && this.Schedule_Time ? scheduleDate : new Date(),
        Schedule_Time_Hour: this.Schedule_Time ? parseInt(moment(this.Schedule_Time, "H:m").format("hh")) : 0,
        Schedule_Time_Minute: this.Schedule_Time ? parseInt(moment(this.Schedule_Time, "H:m").format("m")) : 0,
        Schedule_Time_AMPM: this.Schedule_Time ? moment(this.Schedule_Time, "H:m").format("A") : "",
        AB_Testing_Data: {
          Test_Type:
            this.testtype == "subjectline"
              ? "Subject"
              : this.testtype == "from"
              ? "From"
              : this.testtype == "content"
              ? "Content"
              : "",
          Number_Of_Variations: this.numberVariations ? this.numberVariations : 0,
          Percent_To_Test_To_Pick_Winner: this.Percent_To_Test_To_Pick_Winner,
          Winner_Determining_Factor: this.Winner_Determining_Factor,
          Winner_Determining_After_Value: parseInt( this.Winner_Determining_After_Value),
          Winner_Determining_After_Type: this.Winner_Determining_After_Type,
          Sender_List: fromList,
          Subject_List: this.Subject_List,
          Content_List: this.Content_List,
        },
        Thumbnail_Image: this.emailThumbnail,
        Date_Created: new Date(),
        Created_By: "",
        Date_Updated: new Date(),
        Updated_By: "",
      };
      let url;
      let msg;
      url = this.campaignId != 0 ? "/ws/EmailMarketingCampaign/UpdateCampaign" : "/ws/EmailMarketingCampaign/AddCampaign";
      msg = this.campaignId != 0 ? "Campaign updated successfully" : "Campaign added successfully";
      await this.axios
        .post(url, input)
        .then(() => {
          setTimeout(() => {
            this.cancelCampaign();
            this.getCampaignList();
            this.errors.clear();
          }, 100);

          this.$vs.notify({
            title: "Success",
            text: msg,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getSegmentList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingContactSegment/ListSegments")
        .then((response) => {
          let data = response.data;
          this.segmentOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getTagList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingContactTag/ListTags")
        .then((response) => {
          let data = response.data;
          this.tagOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelEmailEditor() {
      if (this.emailDesignerIndex == -1 && !this.emailThumbnail) {
        this.emailContent = null;
      }
      if (this.emailDesignerIndex != -1) {
        this.Content_List.forEach((el, index) => {
          if (index == this.emailDesignerIndex && !el.Draft_Image) {
            el.Draft_Json = "";
          }
        });
      }
      this.elementDivID = "bee-plugin-container";
      this.emailDesignerIndex = null;
      this.emailDesignerCampaign = false;
      this.emailDesignerCampaignBoolean = false;
    },
    openEmailEditor(index) {
      this.emailDesignerCampaignBoolean = true;
      if (index == -1 && !this.emailContent) {
        this.emailDesignActive = true;
        this.emailDesignerIndex = index;
      } else {
        if (index != -1) {
          let cl = this.Content_List.find((ele, i) => i == index);
          if (cl.Draft_Json) {
            this.emailDesignerIndex = index;
            this.authorizeBeeFreePlugin();
          } else {
            this.emailDesignActive = true;
            this.emailDesignerIndex = index;
          }
        } else {
          this.emailDesignerIndex = index;
          this.authorizeBeeFreePlugin();
        }
      }
    },
    authorizeBeeFreePlugin() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/AuthorizeBeeFreePlugin")
        .then((response) => {
          this.emailDesignerCampaign = true;
          let token = response.data;
          this.letEMailToken = token;
          let uid = this.$route.params.id;
          let jwtDecode = VueJwtDecode.decode(
            this.$store.state.authTokenVV[uid].token
          );
          this.jwtCustomerID = jwtDecode.CustomerId;
          var bee;
          var that;
          that = this;
          var config = {
            uid: this.jwtCustomerID,
            container: "bee-plugin-container",
            onSave: function (jsonFile, htmlFile) {
              if (that.emailDesignerIndex == -1) {
                that.emailContent = jsonFile;
              } else {
                let cl = that.Content_List.find(
                  (ele, index) => index == that.emailDesignerIndex
                );
                cl.Draft_Json = jsonFile;
              }
              that.emailDesignerCampaign = false;
              that.emailDesignerCampaignBoolean = false;
              that.getBeeFreeGetThumbnail(
                jsonFile,
                htmlFile,
                that.emailDesignerIndex
              );
            },
          };
          window.BeePlugin.create(token, config, function (instance) {
            bee = instance;
            if (that.emailDesignerIndex == -1 && that.emailContent != null) {
              let template = JSON.parse(that.emailContent);
              bee.start(template);
            } else {
              bee.start(
                "https://github.com/BEE-Plugin/BEE-FREE-templates/blob/master/v.3/one-column.jpg"
              );
            }
            if (that.Content_List && that.emailDesignerIndex != -1) {
              let cl = that.Content_List.find(
                (ele, index) => index == that.emailDesignerIndex
              );
              if (cl.Draft_Json) {
                bee.start(JSON.parse(cl.Draft_Json));
              } else {
                bee.start(
                  "https://github.com/BEE-Plugin/BEE-FREE-templates/blob/master/v.3/one-column.jpg"
                );
              }
            }
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getBeeFreeGetThumbnail(jsonFile, htmlFile, index) {
      this.$vs.loading();
      this.axios
        .post("/ws/EmailMarketingCampaign/GetBeeFreeGetThumbnail", {
          JsonToConvert: jsonFile,
        })
        .then((res) => {
          if (index == -1) {
            this.emailThumbnail = res.data;
            this.emailValidateTemplate = false;
          } else {
            let cl = this.Content_List.find((ele, i) => i == index);
            cl.Draft_Image = res.data;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelCampaign() {
      if (this.campaignId != 0) {
        let obj = this.campaigns.find((ele) => ele.Id == this.campaignId);
        obj.Action = null;
      }
      this.contactCount = 0;
      this.emailValidateTemplate = false;
      this.letEMailToken = null;
      this.elementDivID = "bee-plugin-container";
      this.campaignId = 0;
      this.emailContent = null;
      this.emailThumbnail = null;
      this.Schedule_Time = null;
      this.Schedule_Date = null;
      this.nextTrue = false;
      this.campaignType = "Regular";
      this.campaignName = "";
      this.testtype = null;
      this.Percent_To_Test_To_Pick_Winner = 10;
      this.Winner_Determining_Factor = null;
      this.Winner_Determining_After_Value = 0;
      this.Winner_Determining_After_Type = null;
      this.campaignTo = null;
      this.tagId = [];
      this.segmentId = 0;
      this.numberVariations = null;
      this.campaignSubject = null;
      this.previewText = null;
      this.Content_List = null;
      this.Subject_List = null;
      this.Sender_List = null;
      this.campaignFrom = null;
      this.Addpersonalization = false;
      this.selectMergeTag = null;
      this.createCampaignActive = false;
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    async getCustomField() {
      this.$vs.loading();
      this.customsFields = [];
      await this.axios
        .get("/ws/EmailMarketingSettingsCustomFields/ListCustomFields")
        .then((response) => {
          let data = response.data;
          data.forEach((e) => {
            if (e.Merge_Tag != "" && e.Merge_Tag != null) {
              this.customsFields.push(e);
            }
          });

          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    changeAction(id, action, campaignName,date) {
      if (action) {
        if (action.draftName == "Edit") {
          let d1 = new Date();
          let ds = moment(date).format("YYYY-MM-DD HH:mm:ss");
          if(d1.getTime() >= new Date(ds).getTime()){
            this.campaignId = id;
            this.warningMessage = "This campaign is sent now. You can not edit this campaign.";
            this.warningMessagePopup = true;
          } else {
            this.editCampaign(id);
          }
        } else if (action.draftName == "Delete") {
          // this.deleteCampaign(id);
          this.deleteCampaignPopupActive = true;
          this.deletableCampaignId = id;
          this.deletableCampaignName = campaignName;
        } else if (action.draftName == "Rename") {
          this.getCampaign(id);
          this.renameCampaignPopupActive = true;
        } else if (action.draftName == "View Report") {
          this.getCampaignOverviewReport(id);
        } else {
          this.copyCampaignPopupActive = true;
          this.getCampaign(id);
        }
      }
    },
    cancelDeleteCampaign() {
      if (this.deletableCampaignId) {
        let obj = this.campaigns.find(
          (ele) => ele.Id == this.deletableCampaignId
        );
        obj.Action = null;
      }
      this.deletableCampaignName = null;
      this.deleteCampaignPopupActive = false;
    },
    deleteCampaign(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/DeleteCampaign?id=" + id)
        .then(() => {
          this.cancelDeleteCampaign();
          this.getCampaignList();
          this.$vs.notify({
            title: "Success",
            text: "Campaign deleted successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async editCampaign(id) {
      this.$vs.loading();
      await this.axios
        .get("ws/EmailMarketingCampaign/GetCampaign?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.campaignId = data.Id;
          this.nextTrue = true;
          this.createCampaignActive = true;
          this.campaignName = data.Campaign_Name;
          this.campaignType = data.Campaign_Type;
          this.testtype = data.AB_Testing_Data
            ? data.AB_Testing_Data.Test_Type.toLowerCase() == "subject"
              ? "subjectline"
              : data.AB_Testing_Data.Test_Type.toLowerCase()
            : null;
          this.numberVariations = data.AB_Testing_Data
            ? data.AB_Testing_Data.Number_Of_Variations
            : null;
          this.Percent_To_Test_To_Pick_Winner = data.AB_Testing_Data
            ? data.AB_Testing_Data.Percent_To_Test_To_Pick_Winner
            : 10;
          this.Winner_Determining_After_Type = data.AB_Testing_Data
            ? data.AB_Testing_Data.Winner_Determining_After_Type
            : null;
          this.Winner_Determining_After_Value = data.AB_Testing_Data
            ? data.AB_Testing_Data.Winner_Determining_After_Value
            : 0;
          this.Winner_Determining_Factor = data.AB_Testing_Data
            ? data.AB_Testing_Data.Winner_Determining_Factor
            : null;
          this.campaignTo = this.makeFirstLetterCaps(data.Send_To_Type);

          // console.log("console", this.campaignTo, data.Send_To_Type);
          this.tagId = data.Send_To_Tag_Ids ? data.Send_To_Tag_Ids : [];
          this.Addpersonalization = data.Send_To_Personalization;
          this.campaignFrom = this.campaignFromOptions.find(
            (ele) => ele.Id == data.Send_From_Id
          );
          this.segmentId = data.Send_To_Segment_Id;
          this.selectMergeTag = data.Send_To_Merge_Tag_Id;
          this.campaignSubject = data.Subject;
          this.previewText = data.Subject_Preview;
          if (this.testtype) {
            if (this.testtype == "content") {
              this.Content_List = data.AB_Testing_Data.Content_List;
            } else if (this.testtype == "from") {
              this.Sender_List = [];
              data.AB_Testing_Data.Sender_List.forEach((e) => {
                this.Sender_List.push({ Id: e });
              });
            } else {
              this.Subject_List = data.AB_Testing_Data.Subject_List;
            }
          }
          if (data.Content) {
            this.emailContent = data.Content;
            if (data.Thumbnail_Image != null) {
              this.emailThumbnail = data.Thumbnail_Image;
            } else {
              this.getBeeFreeGetThumbnail(data.Content, null, -1);
            }
          }

          if (data.IsScheduled == true) {
            this.IsScheduled = true;
            this.Schedule_Date = moment(data.Schedule_Date).format("YYYY-MM-DD");
            this.Schedule_Time = moment(data.Schedule_Date, "H:m").format("HH:mm");
          }
          // console.log("subject", this.campaignSubject);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getCampaign(id) {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaign/GetCampaign?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.selectedCampaignDetail = data;
          if (this.copyCampaignPopupActive) {
            this.createNameforCopyCampaign(
              this.selectedCampaignDetail.Campaign_Name
            );
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    createNameforCopyCampaign(campaignName) {
      let similarCampaignNameArray = 1;
      let partialCampaignName;
      if (campaignName.includes("copy")) {
        for (let i = 0; i < this.campaignsNameArray.length; i++) {
          if (this.campaignsNameArray[i].includes(campaignName)) {
            if (
              !isNaN(this.campaignsNameArray[i].slice(campaignName.length - 1))
            ) {
              if (
                this.campaignsNameArray[i].slice(campaignName.length - 1) >
                similarCampaignNameArray
              ) {
                similarCampaignNameArray = this.campaignsNameArray[i].slice(
                  campaignName.length - 1
                );
              }
            }
          }
        }
        similarCampaignNameArray++;
        partialCampaignName = campaignName.slice(0, campaignName.length - 1);
        this.selectedCampaignDetail.Campaign_Name =
          partialCampaignName + similarCampaignNameArray;
      } else {
        this.selectedCampaignDetail.Campaign_Name = campaignName + " - copy";
      }
    },
    cancelRenameCampaign() {
      if (this.selectedCampaignDetail.Id) {
        let obj = this.campaigns.find(
          (ele) => ele.Id == this.selectedCampaignDetail.Id
        );
        obj.Action = null;
      }
      this.renameCampaignPopupActive = false;
      this.selectedCampaignDetail = null;
    },
    renameCampaign() {
      this.$vs.loading();
      this.axios
        .post(
          "/ws/EmailMarketingCampaign/UpdateCampaign",
          this.selectedCampaignDetail
        )
        .then(() => {
          this.getCampaignList();
          this.cancelRenameCampaign();
          this.$vs.notify({
            title: "Success",
            text: "Campaign renamed successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    cancelCopyCampaign() {
      if (this.selectedCampaignDetail.Id) {
        let obj = this.campaigns.find(
          (ele) => ele.Id == this.selectedCampaignDetail.Id
        );
        obj.Action = null;
      }
      this.copyCampaignPopupActive = false;
      this.selectedCampaignDetail = null;
    },
    copyCampaign() {
      this.$vs.loading();
      this.axios
        .post("/ws/EmailMarketingCampaign/AddCampaign",this.selectedCampaignDetail)
        .then(() => {
          setTimeout(() => {
            this.cancelCopyCampaign();
            this.getCampaignList();
            this.errors.clear();
          }, 100);
          this.$vs.notify({
            title: "Success",
            text: "Campaign copied successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    searchCampaignByName() {
      if (this.searchTerm) {
        if (this.tempCampaignsStore) {
          this.campaigns = this.tempCampaignsStore;
          this.tempCampaignsStore = null;
        }
        var resultObject = this.searchInObj(this.searchTerm, this.campaigns);
        this.tempCampaignsStore = this.campaigns;
        this.campaigns = resultObject;
      } else {
        this.refreshCampaignListAfterSearch();
      }
    },
    searchInObj(nameKey, myArray) {
      var arrrr = [];
      for (var i = 0; i < myArray.length; i++) {
        // if (myArray[i].Campaign_Name === nameKey) {
        //     arrrr.push(myArray[i]);
        // }
        if (myArray[i].Campaign_Name.search(nameKey) != -1) {
          arrrr.push(myArray[i]);
        }
      }
      return arrrr;
    },
    refreshCampaignListAfterSearch() {
      this.campaigns = this.tempCampaignsStore;
      this.tempCampaignsStore = null;
      this.searchTerm = null;
    },
    listSavedEmailTemplates() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/ListSavedEmailTemplates")
        .then((response) => {
          let data = response.data;
          this.savedEmailTemplatesList = data;
          this.savedEmailTemplatesList.map(function (manipulatedData) {
            manipulatedData.sortableDate = new Date(
              manipulatedData.Date_Updated
            );
            return manipulatedData;
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    cancelDeleteSavedEmailTemplate() {
      this.deleteEmailTemplatePopupActive = false;
      this.deletableEmailTemplate = null;
      this.deletableEmailTemplateId = null;
    },
    deleteSavedEmailTemplate(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/DeleteSavedEmailTemplate?id=" + id)
        .then(() => {
          this.listSavedEmailTemplates();
          this.cancelDeleteSavedEmailTemplate();
          this.$vs.notify({
            title: "Success",
            text: "Email Template deleted successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    searchEmailTemplateByName() {
      if (this.emailTemplateSearchTerm) {
        if (this.tempEmailTemplatesStore) {
          this.savedEmailTemplatesList = this.tempEmailTemplatesStore;
          this.tempEmailTemplatesStore = null;
        }
        var resultObject = this.searchEmailTemplateInObj(
          this.emailTemplateSearchTerm,
          this.savedEmailTemplatesList
        );
        this.tempEmailTemplatesStore = this.savedEmailTemplatesList;
        this.savedEmailTemplatesList = resultObject;
      } else {
        this.refreshEmailTemplatesListAfterSearch();
      }
    },
    searchEmailTemplateInObj(nameKey, myArray) {
      var arrrr = [];
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].Template_Name.search(nameKey) != -1) {
          arrrr.push(myArray[i]);
        }
      }
      return arrrr;
    },
    refreshEmailTemplatesListAfterSearch() {
      this.savedEmailTemplatesList = this.tempEmailTemplatesStore;
      this.tempEmailTemplatesStore = null;
      this.emailTemplateSearchTerm = null;
    },
    listEmailTemplateFolders() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/ListEmailTemplateFolders")
        .then((response) => {
          let data = response.data;
          this.emailTemplateFoldersList = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    cancelCreateEditEmailTemplateEditor() {
      this.editEmailTemplateMode = false;
      this.createEmailTemplateTitle = null;
      this.createEditEmailTemplatePopupActive = false;
      this.createEditEmailTemplatePopupBoolean = false;
      this.createEditEmailTemplateContent = null;
      this.createEditEmailTemplateImage = null;
      this.createEditEmailTemplateName = null;
      this.createEditEmailTemplateFolder = null;
      this.createEditEmailTemplateDescription = null;
      this.createEditEmailTemplateId = 0;
    },
    openCreateEditEmailTemplateEditor() {
      this.createEditEmailTemplatePopupBoolean = true;
      this.authorizeBeeFreePluginForCreateEditEmailTemplate();
    },
    authorizeBeeFreePluginForCreateEditEmailTemplate() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/AuthorizeBeeFreePlugin")
        .then((response) => {
          this.createEditEmailTemplatePopupActive = true;
          let token = response.data;
          let uid = this.$route.params.id;
          let jwtDecode = VueJwtDecode.decode(
            this.$store.state.authTokenVV[uid].token
          );
          this.jwtCustomerID = jwtDecode.CustomerId;
          var beeFree;
          var that;
          that = this;
          var config = {
            uid: this.jwtCustomerID,
            container: "create-update-bee-plugin-container",
            onSave: function (jsonFile, htmlFile) {
              that.createEditEmailTemplateContent = jsonFile;
              that.emailDesignerCampaign = false;
              that.emailDesignerCampaignBoolean = false;
              // that.cancelCreateEditEmailTemplateEditor()
              that.getBeeFreeGetThumbnailForCreateEditEmailTemplate(
                jsonFile,
                htmlFile
              );
            },
          };

          window.BeePlugin.create(token, config, function (instance) {
            beeFree = instance;
            if (that.createEditEmailTemplateContent != null) {
              let template = JSON.parse(that.createEditEmailTemplateContent);
              beeFree.start(template);
            } else {
              beeFree.start(
                "https://github.com/BEE-Plugin/BEE-FREE-templates/blob/master/v.3/one-column.jpg"
              );
            }
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getBeeFreeGetThumbnailForCreateEditEmailTemplate(jsonFile) {
      this.$vs.loading();
      await this.axios
        .post("/ws/EmailMarketingCampaign/GetBeeFreeGetThumbnail", {
          JsonToConvert: jsonFile,
        })
        .then((res) => {
          this.createEditEmailTemplateImage = res.data;
          this.addEditSavedEmailTemplate();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    addEditSavedEmailTemplate() {
      let addEditSavedEmailTemplateParams = {
        Id: this.createEditEmailTemplateId,
        Template_Name: this.createEditEmailTemplateName,
        Folder_Id: this.createEditEmailTemplateFolder,
        Template_Image: this.createEditEmailTemplateImage,
        Template_Description: this.createEditEmailTemplateDescription,
        Json_Content: this.createEditEmailTemplateContent,
      };
      this.$vs.loading();
      let endpoint;
      let successMessage;
      if (this.editEmailTemplateMode) {
        endpoint = "/ws/EmailMarketingCampaign/UpdateSavedEmailTemplate";
        successMessage = "Email Template updated successfully.";
      } else {
        endpoint = "/ws/EmailMarketingCampaign/AddSavedEmailTemplate";
        successMessage = "Email Template created successfully.";
      }
      this.axios
        .post(endpoint, addEditSavedEmailTemplateParams)
        .then(() => {
          setTimeout(() => {
            this.cancelCreateEditEmailTemplateEditor();
            this.listSavedEmailTemplates();
            this.errors.clear();
          }, 100);
          this.$vs.notify({
            title: "Success",
            text: successMessage,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getSavedEmailTemplate(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/GetSavedEmailTemplate?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.createEditEmailTemplateId = data.Id;
          this.createEditEmailTemplateContent = data.Json_Content;
          this.createEditEmailTemplateImage = data.Template_Image;
          this.createEditEmailTemplateName = data.Template_Name;
          this.createEditEmailTemplateFolder = data.Folder_Id;
          this.createEditEmailTemplateDescription = data.Template_Description;
          this.openCreateEditEmailTemplateEditor();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelAddUpdateEmailTemplateFolder() {
      // this.$refs.theSelect.clearSelection()
      this.deleteFolderPopupActive = false;
      this.editFolderMode = false;
      this.addEditFolderPopupTitle = null;
      this.addEditFolderPopupActive = false;
      this.editableFolderId = 0;
      this.editableFolderName = null;
      this.editableFolderItemCount = "0";
    },
    addUpdateEmailTemplateFolder() {
      let addUpdateFolderParams = {
        Id: this.editableFolderId,
        Folder_Name: this.editableFolderName,
        Current_Item_Count: this.editableFolderItemCount,
      };
      this.$vs.loading();
      let endpoint;
      let successMessage;
      if (this.editFolderMode) {
        endpoint = "/ws/EmailMarketingCampaign/UpdateEmailTemplateFolder";
        successMessage = "Folder updated successfully.";
      } else {
        endpoint = "/ws/EmailMarketingCampaign/AddEmailTemplateFolder";
        successMessage = "Folder created successfully.";
      }
      this.axios
        .post(endpoint, addUpdateFolderParams)
        .then(() => {
          setTimeout(() => {
            this.cancelAddUpdateEmailTemplateFolder();
            this.listEmailTemplateFolders();
            this.errors.clear();
          }, 100);
          this.$vs.notify({
            title: "Success",
            text: successMessage,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    deleteFolder() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/DeleteEmailTemplateFolder?id=" +this.editableFolderId)
        .then(() => {
          this.cancelAddUpdateEmailTemplateFolder();
          this.listEmailTemplateFolders();
          this.$vs.notify({
            title: "Success",
            text: "Folder deleted successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    cancelCopyEmailTemplate() {
      this.copyEmailTemplatePopupActive = false;
      this.copyEmailTemplateDetails = null;
    },
    getSavedEmailTemplateForCopy(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/GetSavedEmailTemplate?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.copyEmailTemplateDetails = data;
          this.copyEmailTemplatePopupActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    copyEmailTemplate() {
      this.$vs.loading();
      this.axios
        .post("/ws/EmailMarketingCampaign/AddSavedEmailTemplate",this.copyEmailTemplateDetails)
        .then(() => {
          setTimeout(() => {
            this.cancelCreateEditEmailTemplateEditor();
            this.listSavedEmailTemplates();
            this.errors.clear();
          }, 100);
          this.$vs.notify({
            title: "Success",
            text: "Email Template copied successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    sortEmailTemplate() {
      this.savedEmailTemplatesList.sort(
        this.GetSortOrder(
          this.emailTemplateSortingOrder.orderBy,
          this.emailTemplateSortingOrder.key
        )
      );
    },
    closeViewReport() {
      this.filterBy = null;
      if (this.viewReportDetails.Campaign_Id) {
        let obj = this.campaigns.find(
          (ele) => ele.Id == this.viewReportDetails.Campaign_Id
        );
        obj.Action = null;
      }
      this.limitedSubcribers = 5;
      this.limitedTopLinks = 5;
      this.CampaignViewReportActive = false;
      this.subjectList = [];
      this.senderList = [];
      this.linksummaryData = [];
      this.filterContacts = [];
      this.viewReportDetails = null;
      this.linkSelected = null;
    },
    getCampaignOverviewReport(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/GetCampaignOverviewReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          this.viewReportDetails = data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          data.Twentyfour_Hour_Performance.forEach((e) => {
            x.push(e.x);
            FirstGroup.push(e.FirstGroup);
            SecondGroup.push(e.SecondGroup);
          });
          this.lineChartSimple.series = [
            {
              name: "Opens",
              data: FirstGroup,
            },
            {
              name: "Clicks",
              data: SecondGroup,
            },
          ];
          this.lineChartSimple.chartOptions = {
            ...this.lineChartSimple.chartOptions,
            ...{
              xaxis: {
                categories: x,
                labels: {
                  hideOverlappingLabels: true,
                  showDuplicates: false,
                },
              },
            },
          };
          if (data.Campaign_Type == "AB") {
            this.getABSenderResult(id);
            this.getABSubjectResult(id);
          }
          this.getLinkSummaryData(id);
          this.getFilterContact(id);
          this.CampaignViewReportActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getABSenderResult(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/CampaignABTestSenderReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          if (data.length > 0) {
            data.forEach((e) => {
              e.Open_Rate_Chart = {
                series: [e.Open_Rate == "NaN" ? 0 : e.Open_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
              e.Click_Rate_Chart = {
                series: [e.Click_Rate == "NaN" ? 0 : e.Click_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
            });
            this.senderListReport = data;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getABSubjectResult(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/CampaignABTestSubjectReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          if (data.length > 0) {
            data.forEach((e) => {
              e.Open_Rate_Chart = {
                series: [e.Open_Rate == "NaN" ? 0 : e.Open_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
              e.Click_Rate_Chart = {
                series: [e.Click_Rate == "NaN" ? 0 : e.Click_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
            });
            this.subjectListReport = data;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getEmailPreBuiltTemplate() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaign/ListAvailablePreBuiltTemplates")
        .then((response) => {
          let data = response.data;
          this.preBuiltTemplates = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getLinkSummaryData(id) {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaignReporting/CampaignLinkSummaryReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          this.linksummaryData = data.Link_Summary;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getFilterContact(id) {
      this.$vs.loading();
      let input = {
        Campaign_ID: id,
        Fitler_Type: this.filterBy ? this.filterBy.filterName : "",
        Link_ID: this.linkSelected ? this.linkSelected.Link_Id : 0,
      };
      //let input = {
      //   Campaign_ID: 30,
      //   Fitler_Type: "Opened",
      //   Link_ID: 0,
      // };
      this.axios
        .post("/ws/EmailMarketingCampaignReporting/FilterContacts", input)
        .then((res) => {
          let data = res.data;
          this.filterContacts = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getTagContactCount() {
      let input = {
        "Ids":this.tagId,
      }
      this.$vs.loading();
      this.axios
        .post("ws/EmailMarketingContactTag/GetTagContactCount",input)
        .then((response) => {
          let data = response.data;
          this.contactCount = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getSegmentContactCount() {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingContactSegment/GetSegmentContactCount?Id=" + this.segmentId)
        .then((response) => {
          let data = response.data;
          this.contactCount = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async showContactPopup(){
       let listContactsParams = {
          Fitler_Type: this.campaignTo,
          Filter_Segment: this.segmentId != 0?this.segmentId.toString():"",
          Filter_Tag: this.tagId.map(String),
          Filter_Search_Value: "",
          Order_By: "Email_Address",
          Order_By_Type: "asc",
          Number_Per_Page: 0,
          Skip_Number_Of_Pages: 0,
        };
         this.$vs.loading();
      this.axios
        .post("/ws/EmailMarketingContact/ListContacts", listContactsParams)
        .then((response) => {
          let data = response.data;
          this.contactsData = data;
          for (let i = 0; i < this.contactsData.length; i++) {
            for (let j = 0; j < this.contactsData[i].CustomFields.length; j++) {
              if (
                this.contactsData[i].CustomFields[j].Field_Type.Field_Type ==
                  "dropdown" ||
                this.contactsData[i].CustomFields[j].Field_Type.Field_Type ==
                  "radio"
              ) {
                for (
                  let k = 0;
                  k < this.contactsData[i].CustomFields[j].Options.length;
                  k++
                ) {
                  if (
                    this.contactsData[i].CustomFields[j].Current_Value ==
                    this.contactsData[i].CustomFields[j].Options[k].Id
                  ) {
                    this.contactsData[i][
                      this.contactsData[i].CustomFields[j].Field_Label.replace(
                        / /g,
                        "_"
                      )
                    ] = this.contactsData[i].CustomFields[j].Options[
                      k
                    ].Possible_Value;
                  }
                }
              } else {
                this.contactsData[i][
                  this.contactsData[i].CustomFields[j].Field_Label.replace(
                    / /g,
                    "_"
                  )
                ] = this.contactsData[i].CustomFields[j].Current_Value;
              }
            }
          }
          this.contactDetailsActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getContactFieldDefinition() {
      if(this.contactCount ==0){
         if(this.campaignTo){
           this.warningMessage = "No contacts found related to the segment or tag you selected";
         } else {
           this.warningMessage = "Please select 'Send this campagin To' to get appropriate recipents count";  
         }
         this.warningMessagePopup = true;
         return;
      }
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingContact/GetContactFieldDefinition")
        .then((response) => {
          let data = response.data;
          this.contactsTableFieldDefinition = data;
          this.dynamicCustomField = [];
          for (let i = 0; i < this.contactsTableFieldDefinition.length; i++) {
            if (
              !this.contactsTableFieldDefinition[i].IsDefault &&
              this.contactsTableFieldDefinition[i].Field_Data_Type != "image"
            ) {
              this.dynamicCustomField.push(
                this.contactsTableFieldDefinition[i]
              );
            }
            var fieldName = this.contactsTableFieldDefinition[
              i
            ].Field_Name.replace(/ /g, "_");
            var fieldLabel = this.contactsTableFieldDefinition[i].Field_Label;
            if (this.contactsTableFieldDefinition[i].Display_Column) {
              if (
                this.contactsTableFieldDefinition[i].Field_Data_Type != "image"
              ) {
                if (fieldName == "Email_Address") {
                  this.columnDefs.push({
                    headerName: fieldLabel,
                    field: fieldName,
                    width: 200,
                    pinned: "left",
                    filter: true,
                    checkboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    headerCheckboxSelection: true,
                    cellRenderer: "contactDetailsLink",
                  });
                } else if (fieldName == "Address") {
                  this.columnDefs.push({
                    headerName: fieldLabel,
                    valueGetter: joinAddresses,
                    filter: true,
                    width: 250,
                  });
                } else {
                  this.columnDefs.push({
                    headerName: fieldLabel,
                    field: fieldName,
                    filter: true,
                    width: 140,
                  });
                }
              }
            }
          }
          this.$vs.loading.close();
          this.showContactPopup();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelWarningPopup(){
      if(this.campaignId != 0){
        let obj = this.campaigns.find((ele) => ele.Id == this.campaignId);
        obj.Action = null;
      }
      this.campaignId = 0;
      this.warningMessagePopup = false;
      this.warningMessage='';
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
</style>